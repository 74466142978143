<template>
  <section>
    <Breadcrumb
      current-page-name="لیست علاقه‌مندی‌ها"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <div class="col-lg-8 customer-panel-box">
          <div class="custom-table-container mt-3">
            <table class="custom-table">
              <thead>
                <tr class="h">
                  <th>تصویر</th>
                  <th>عنوان</th>
                  <th>نویسنده</th>
                  <th>مترجم</th>
                  <th>قیمت</th>
                  <th>حذف از لیست</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>
                    <img
                      width="50px"
                      class="product-img"
                      style="cursor: pointer"
                      @click="$router.push('/product/' + product.slug)"
                      :src="product.image"
                      alt="img-product"
                    />
                  </td>
                  <td
                    style="cursor: pointer"
                    @click="$router.push('/product/' + product.slug)"
                  >
                    {{ product.name }}
                  </td>
                  <td>{{ product.author }}</td>
                  <td>{{ product.translator }}</td>
                  <td>
                    <!-- special price -->
                    <div
                      v-if="
                        product.special_price && product.special_price !== 0
                      "
                    >
                      <div class="d-flex">
                        <p class="old-price">
                          {{ new Intl.NumberFormat().format(product.price) }}
                          ریال
                        </p>
                        <p class="discount-percent mr-2">
                          {{
                            new Intl.NumberFormat().format(
                              (
                                ((Number(product.price) -
                                  Number(product.special_price)) /
                                  Number(product.price)) *
                                100
                              ).toFixed(0)
                            ) + "%"
                          }}
                        </p>
                      </div>
                      <p class="new-price">
                        {{
                          new Intl.NumberFormat().format(product.special_price)
                        }}
                        ریال
                      </p>
                    </div>
                    <!-- special price -->

                    <!-- price -->
                    <p v-else class="new-price">
                      {{ new Intl.NumberFormat().format(product.price) }}
                      ریال
                    </p>
                    <!-- price -->
                  </td>
                  <td @click="remove(product.id)">
                    <img
                      class="remove-btn"
                      src="/template/images/zarbdar.svg"
                      alt=""
                      width="25px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";

export default {
  components: { Breadcrumb, SideMenu },
  data() {
    return {
      products: []
    };
  },
  created() {
    this.$store.dispatch("getFavorite").then(result => {
      this.products = result.item;
    });
  },
  methods: {
    async remove(productId) {
      if (productId) {
        await this.$store.dispatch("likeAction", { productId });
        window.location.href = "/profile/favorite";
      }
    }
  }
};
</script>
<style scoped>
.custom-table {
  width: 100%;
}

.custom-table thead {
  background-color: #f5f5f5;
}

.remove-btn {
  background-color: red;
  border-radius: 50%;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  .custom-table-container {
    width: 600px;
    overflow-x: auto !important;
  }
}
</style>
