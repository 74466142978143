<template>
  <div>
    <router-link tag="div" to="/profile/wallet/">
      <i class="fa fa-money" aria-hidden="true"></i>
      <label style="margin-right: 5px">اعتبار:</label>
      <div
        v-if="loadingBalance"
        class="spinner-border spinner-border-sm"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>

      <b v-else> {{ new Intl.NumberFormat().format(wallet.amount) }} ریال</b>
    </router-link>
  </div>
</template>

<style scoped></style>

<script>
export default {
  name: "Walletinfo",
  data: function() {
    return {
      loadingBalance: true,
      wallet: []
    };
  },
  created() {
    this.$store.dispatch("getWalletBalance").then(result => {
      this.wallet = result.wallet;
      this.loadingBalance = false;
    });
  }
};
</script>
