<template>
  <div class="screen-dashboard-sidemenu col-lg-3">
    <ul>
      <router-link tag="li" to="/profile">
        <img src="/template/images/counter-in.svg" alt="" />
        <span>پیشخوان</span>
      </router-link>
      <router-link tag="li" to="/profile/edit">
        <img class="out" src="/template/images/user-in.svg" alt="" />
        <span>اطلاعات کاربری</span>
      </router-link>
      <router-link tag="li" to="/profile/wallet">
        <img src="/template/images/user-in.svg" alt="" />
        <span>کیف پول</span>
      </router-link>
      <router-link tag="li" to="/profile/orders">
        <img src="/template/images/order-in.svg" alt="" />
        <span>سفارش‌های من</span>
      </router-link>
      <router-link tag="li" to="/profile/address">
        <img class="out" src="/template/images/Pin-in.svg" alt="" />
        <span>آدرس‌های من</span>
      </router-link>
      <router-link tag="li" to="/profile/favorite">
        <img class="out" src="/template/images/see-more.svg" alt="" />
        <span>لیست علاقه‌مندی‌ها</span>
      </router-link>
      <router-link tag="li" to="/profile/comments">
        <img src="/template/images/comment-in.svg" alt="" />
        <span>دیدگاه‌های من</span>
      </router-link>
      <li @click="logoutProfile()">
        <img src="/template/images/exit-account.svg" alt="" />
        <span>خروج از حساب کاربری</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  data: function() {
    return {
      fullname: this.$store.getters.getProfileInfo.fullname ?? "",
      phone: this.$store.getters.getProfileInfo.phone ?? ""
    };
  },
  methods: {
    logoutProfile() {
      this.$swal
        .fire({
          position: "center",
          icon: "warning",
          title: "آیا از خروج خود اطمینان دارید؟",
          showCancelButton: true,
          confirmButtonText: `تایید`,
          cancelButtonText: `انصراف`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch("logout").then(() => {
              window.location.href = "/auth";
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.screen-dashboard-sidemenu {
  box-shadow: 1px 0px 7px -3px #000000;
  border-radius: 15px;
  margin: 2rem 1rem;
  padding: 1rem;
  max-width: 350px;
  height: 500px;
}
.screen-dashboard-sidemenu ul {
  list-style: none;
}
.screen-dashboard-sidemenu ul li {
  text-align: right;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;
  width: max-content;
}
.screen-dashboard-sidemenu ul li span {
  padding-bottom: 0.5rem;
}
.screen-dashboard-sidemenu
  ul
  li.router-link-exact-active.router-link-active
  span {
  border-bottom: 2px solid #1c403e;
  color: #1c403e;
}
.screen-dashboard-sidemenu ul li img,
.screen-dashboard-sidemenu ul li i {
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
}
/* @media screen and (min-width: 850px) and (max-width: 1015px) {
  .screen-dashboard-sidemenu {
    width: 250px !important;
  }
} */

/* customer panel sidebar in mobile */
@media screen and (max-width: 970px) {
  .screen-dashboard-sidemenu {
    padding: 0;
    max-width: 100% !important;
    width: 100% !important;
    height: 60px !important;
  }
  .screen-dashboard-sidemenu ul {
    display: flex;
    width: 100% !important;
    white-space: nowrap;
    overflow-x: auto !important;
    margin: 0 !important;
  }
  .screen-dashboard-sidemenu ul li {
    margin: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .screen-dashboard-sidemenu {
    margin: 2rem 0rem !important;
  }
}
</style>
