import axios from "axios";

import { env } from "../../../enums";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPaymentWays() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/paymentway", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    }
  }
};
