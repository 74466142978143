import axios from "axios";
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers["crossDomain"] = true;
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
import { env } from "../../../enums";

export default {
  state: {
    profileInfo: {
      phone: "",
      email: "",
      fullname: "",
      melicode: "",
      store_name: ""
    },
    auth: {
      routineLevel: "AUTH",
      codeWrong: false,
      token: null
    },
    isNew: false,
    error: false,
    success: "",
    addresses: [],
    location: {
      provinces: [],
      cities: []
    }
  },
  getters: {
    getAddresses(state) {
      return state.addresses;
    },
    getLocations(state) {
      return state.location;
    },
    getProfileInfo(state) {
      return state.profileInfo;
    },
    getErrors(state) {
      return state.error;
    }
  },
  mutations: {
    setProvinceList(state, data) {
      state.location.provinces = data;
    },
    setCityList(state, data) {
      state.location.cities = data;
    },
    logoutAction(state) {
      localStorage.removeItem("token");
      state.auth.token = null;
    },
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    setCodeWrong(state, message) {
      state.error = message;
    },
    setSuccessEvent(state, message) {
      state.success = message;
    },
    setAuthRoutineLevel(state, data) {
      state.auth.routineLevel = data.status;
      state.auth.token = data.token;
    },
    setIsNew(state, isNew) {
      state.isNew = isNew;
    },
    setProfilePhone(state, phone) {
      state.profileInfo.phone = phone;
    },
    setProfileInfo(state, data) {
      state.profileInfo.email = data.email;
      state.profileInfo.fullname = data.fullname;
      state.profileInfo.melicode = data.melicode;
      state.profileInfo.phone = data.phone;
      state.profileInfo.store_name = data.store_name;
      state.profileInfo.personnel_code = data.personnel_code;

    }
  },
  actions: {
    setProfileQuickAccess(context) {
      context.commit("changeProfileQuickAccess");
    },
    getProfile() {
      return new Promise(function(resolve, reject) {
        axios
          .get(env.base_url + "/account/profile", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status == 200) {
              resolve(result.data.item);
            }
            reject({});
          })
          .catch(error => {
            if (error.response.status === 401) {
              resolve(error.response.status);
            }
          });
      });
    },
    getProfileX(context) {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + "/account/profile", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status === 200) {
              context.commit("setProfileInfo", result.data.item);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    getFavorite() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/account/profile/favorites", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getComments() {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + "/account/profile/comments", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            resolve(result.data.items);
          });
      });
    },
    getAddresses() {
      return new Promise(function(resolve, reject) {
        axios
          .get(env.base_url + "/account/profile/addresses", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status === 200) {
              resolve(result.data.item);
            }
            reject([]);
          });
      });
    },
    getShipping() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/shipping").then(result => {
          if (result.status === 200) {
            resolve(result.data.items);
          }
          reject([]);
        });
      });
    },
    getProvince(context) {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + "/location/province")
          .then(result => {
            if (result.status === 200) {
              context.commit("setProvinceList", result.data.item);
              resolve(result.data.item);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    getCity(context, { province }) {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + "/location/city?parent_id=" + province)
          .then(result => {
            if (result.status === 200) {
              context.commit("setCityList", result.data.item);
              resolve(result.data.item);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    saveProfileInfo(context, { fullname, phone, email }) {
      return new Promise(resolve => {
        axios
          .post(
            env.base_url + "/account/profile",
            {
              fullname,
              phone,
              email
              // store_name
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    saveAddress(context, { data }) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            env.base_url + "/account/profile/address/add",
            {
              ...data
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              resolve({
                success: true
              });
            } else {
              reject({
                success: false
              });
            }
          });
      });
    },
    deleteAddress(context, { id }) {
      return new Promise(function(resolve, reject) {
        axios
          .delete(env.base_url + "/account/profile/address/" + id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status === 200) {
              resolve({
                success: true
              });
            } else {
              reject({
                success: false
              });
            }
          });
      });
    },
    editAddress(context, { id }) {
      return new Promise(function(resolve, reject) {
        axios
          .get(env.base_url + "/account/profile/address/" + id, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status === 200) {
              resolve(result.data.item);
            } else {
              reject({
                success: false
              });
            }
          });
      });
    },
    updateAddress(context, { id, data }) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            env.base_url + "/account/profile/address/" + id,
            {
              ...data
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              resolve({
                success: true
              });
            } else {
              reject({
                success: false
              });
            }
          });
      });
    },
    saveContact(context, { data }) {
      return new Promise(function(resolve, reject) {
        axios
          .post(env.base_url + "/feedback/contact", {
            ...data
          })
          .then(result => {
            if (result.status === 200) {
              resolve({
                success: true
              });
            } else {
              reject({
                success: false
              });
            }
          });
      });
    },
    subscribe_newsletter(context, { email }) {
      return new Promise(function(resolve) {
        axios
          .post(
            env.base_url + "/newsletter/register",
            {
              email
            },
            {}
          )
          .then(result => {
            return resolve(result.data);
          })
          .catch(result => {
            return resolve(result.data);
          });
      });
    }
  }
};
