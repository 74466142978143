<template>
  <section class="faq">
    <breadcrumb current-page-name="سؤال‌های متداول"></breadcrumb>
    <div class="wrapper">
      <div class="col-xl-8 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
        </h5>

        <div class="container">
          <div class="panel-group" id="accordion">
            <div v-for="(item , index) in list" :key="index" class="panel panel-default">
              <div class="panel-heading">
                <h6 class="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse1"
                    > {{item.title}}
                  </a>
                  <i class="fa fa-caret-down f-left"> </i>
                </h6>
              </div>
              <div id="collapse1" class="panel-collapse collapse in">
                <div class="panel-body">
                  {{ item.description}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";

export default {
  name: "information",
  components: { Breadcrumb },
  data() {
    return {
      title:"",
      list: [],
    };
  },
  created() {
    //get details from backend
    this.$store.dispatch("getFaq").then((result) => {
      if (result) {
        this.list = result;
      }
    });
  },
};
</script>
<style scoped>
.panel-heading {
  text-align: right;
  padding: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}
a {
  color: black !important;
}
.panel-body {
  text-align: right;
  padding: 1rem;
  border: 1px solid #ddd;
}
.f-left {
  float: left;
}
</style>
