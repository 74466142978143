<template>
  <section class="product-section">
    <div class="custom-container">
      <div class="d-flex justify-content-between mt-4 mb-4">
        <h4 v-show="title" class="category_title">{{ title }}</h4>
        <router-link v-show="slug" :to="'/category/' + slug" class="show-all">
          مشاهده ی همه
          <i class="fa fa-angle-left mr-1 ml-1"></i>
        </router-link>
      </div>
      <carousel
        class="item-carousel"
        style="direction: ltr"
        :perPageCustom="[
          [360, 2],
          [768, 2],
          [1024, 4]
        ]"
        :paginationEnabled="true"
        :navigationEnabled="true"
        navigationNextLabel=""
        navigationPrevLabel=""
        :touchDrag="true"
        :mouseDrag="true"
      >
        <slide
          v-for="(product, index) in products"
          :key="product[index]"
          :title="product.title"
          :data-index="index"
          :data-name="product.title"
        >
          <ProductItem :product="product"></ProductItem>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import ProductItem from "@/components/ProductItem.component";
export default {
  props: ["title", "slug", "products"],
  components: {
    Carousel,
    Slide,
    ProductItem
  }
};
</script>

<style scoped></style>
