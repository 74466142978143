<template>
  <div class="form col-12">
    <h5 class="mb-3 text-center">ورود با کد پرسنلی</h5>

    <Spinner v-if="loading" />

    <div v-else class="w-100">
      <!-- Show error message -->
      <p v-if="errorMessage" class="text-center" style="color: red">
        {{ errorMessage }}
      </p>

      <!-- Login inputs -->
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>کد پرسنلی:</label>
          <span
              v-if="emailError"
              class="mr-3 mb-1"
              style="font-size: 12px; color: red"
          >
            کد پرسنلی الزامی است
          </span>
        </div>
        <input
            v-model="email"
            ref="email"
            class="input mt-0"
            type="number"
            name="email"
            placeholder="کد پرسنلی"
        />
      </div>

      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>رمز عبور:</label>
          <span
              v-if="passwordError"
              class="mr-3 mb-1"
              style="font-size: 12px; color: red"
          >
            رمزعبور الزامی است
          </span>
        </div>
        <input
            v-model="password"
            ref="password"
            class="input mt-0"
            type="password"
            name="password"
            placeholder="رمز عبور"
        />
      </div>

      <!-- Submit button -->
      <div class="d-flex">
        <input
            @click="login"
            class="submit-btn w-100 ml-3"
            type="button"
            value="ورود"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "login",
  components: { Spinner },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    emailError() {
      return !this.email.trim();
    },
    passwordError() {
      return !this.password.trim();
    },
  },
  methods: {
    async login() {
      try {
        const result = await this.$store.dispatch("authUP/login", {
          email: this.email,
          password: this.password,
        });
        if (result) {
          console.log(result);
          //sync basket
          this.$store.dispatch("updateBasketReq").then(res => {
                if (res) {
                  this.$store.dispatch("getBasketReq");
                }
              });

              //set token
              this.$store.commit("setAuthRoutineLevel", {
                state: "WELCOME",
                token: result.token
              });

              //disable loader
              this.loading = false;

              //go to befor
              this.$router.push({ name: "ProfileInfo" });
        } else {
          this.errorMessage = "نام کاربری یا رمز عبور اشتباه است.";
        }
      } catch (error) {
        console.error("Login failed:", error);
        this.errorMessage = "مشکلی در ورود رخ داده است.";
      }
    },
  },

};
</script>

<style scoped>
/* Add custom styles here */
</style>
