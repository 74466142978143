<template>
  <section>
    <Breadcrumb current-page-name="کیف پول"></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <div class="col-lg-8 customer-panel-box">
          <div align="center">
            <b> وضعیت پرداخت</b><br />

            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>

            <div v-else>
              <label>{{ message }}</label
              ><br />
              <label v-if="refid">{{ refid }}کد پیگیری : </label><br /><br />

              <router-link tag="a" class="submit-btn" to="/profile/wallet"
                >بازگشت به کیف پول
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.row_transaction {
  text-align: right;
}

.submit-btn {
  color: white !important;
}
</style>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";

export default {
  name: "Wallet",
  data() {
    return {
      loading: true,
      message: "",
      refid: ""
    };
  },

  components: { Breadcrumb, SideMenu },
  beforeCreate() {},
  mounted() {},
  created() {
    this.$store
      .dispatch("verifyPayment", {
        Authority: this.$route.query.Authority,
        Status: this.$route.query.Status
      })
      .then(result => {
        this.message = result.message;
        if (result.status == "ok") {
          this.refid = result.result.refid;
        }

        this.loading = false;
      });
  },
  methods: {}
};
</script>
