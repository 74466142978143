<template>
  <Spinner
    v-if="loading && products && products.length !== 0"
    :circle="true"
    class="mt-5 mb-5"
  ></Spinner>

  <!-- latest in home page -->
  <div v-else-if="products && products.length !== 0" class="mb-5">
    <div>
      <a :href="result.banner.url">
        <img
          :src="result.banner.image"
          class="img-responsive mb-5"
          style="width: 100%; max-width: 100%; height: auto; position: relative"
        />
      </a>
    </div>

    <h4 class="text-right" style="margin: 0 2rem">تازه‌ها</h4>
    <br />

    <!-- tablist -->
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <ul class="newrelease-tablist">
        <li
          v-if="result.first_edition_books.length !== 0"
          @click="firstEditionBooks"
          v-bind:class="{ active: products.type == 'first_edition_books' }"
        >
          چاپ اول
        </li>
        <li
          v-if="result.not_first_edition_books.length !== 0"
          @click="notFirstEditionBooks"
          v-bind:class="{
            active: products.type == 'not_first_edition_books'
          }"
        >
          تجدید چاپ
        </li>
        <li
          v-if="result.journals.length !== 0"
          @click="journals"
          v-bind:class="{ active: products.type == 'journals' }"
        >
          نشریات
        </li>
        <li
          v-if="result.not_books.length !== 0"
          @click="notBooks"
          v-bind:class="{ active: products.type == 'not_books' }"
        >
          غیرکتاب
        </li>
      </ul>
      <router-link
        class="d-flex align-items-center ml-4 mr-4 mb-0 pb-1"
        style="cursor: pointer"
        tag="p"
        :to="{
          name: 'search',
          query: {
            sort: 'created'
          }
        }"
      >
        نمایش بیشتر
        <i class="fa fa-angle-left mr-2"></i>
      </router-link>
    </div>

    <div class="newrelease-container">
      <Spinner v-if="loadData" :leanier="true"></Spinner>
      <VueSlickCarousel v-else v-bind="settings">
        <ProductItem
          v-for="(product, index) in products.items"
          :key="product[index]"
          :product="product"
        ></ProductItem>
      </VueSlickCarousel>
    </div>
    <!-- tablist -->
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "LastProducts",
  data: function() {
    return {
      loading: true,
      loadData: false,
      result: {
        journals: [],
        not_books: [],
        first_edition_books: [],
        not_first_edition_books: [],
        banner: {}
      },
      products: {
        type: "",
        items: []
      },
      settings: {
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        speed: 500,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  components: {
    VueSlickCarousel,
    ProductItem,
    Spinner
  },
  mounted() {
    //get products per category
    this.$store.dispatch("getLatestProducts").then(result => {
      this.result = result;
      this.products = {
        type: "first_edition_books",
        items: this.result.first_edition_books
      };
      this.loading = false;
    });
  },
  methods: {
    firstEditionBooks() {
      this.loadData = true;
      this.products = {
        type: "first_edition_books",
        items: this.result.first_edition_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    notFirstEditionBooks() {
      this.loadData = true;
      this.products = {
        type: "not_first_edition_books",
        items: this.result.not_first_edition_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    journals() {
      this.loadData = true;

      this.products = {
        type: "journals",
        items: this.result.journals
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    notBooks() {
      this.loadData = true;

      this.products = {
        type: "not_books",
        items: this.result.not_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    }
  }
};
</script>
<style scoped>
/* tablist */
.newrelease-container {
  min-height: 200px;
  position: relative;
}
.newrelease-tablist {
  list-style: none;
  display: flex;
  padding-right: 1rem;
}
.newrelease-tablist li {
  margin: 0 0.5rem;
  cursor: pointer;
  padding-bottom: 3px;
}
.newrelease-tablist li:hover,
.newrelease-tablist li.active {
  border-bottom: 1px solid #000;
}

@media only screen and (max-width: 570px) {
  .newrelease-container {
    min-height: 200px;
    /* position: relative; */
  }
}
@media only screen and (max-width: 400px) {
  .newrelease-tablist {
    font-size: 14px;
  }
}
</style>
