<template>
  <section class="category">
    <Breadcrumb :sectionNames="breadcrumbs"></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <!-- filters -->
          <div class="filters" v-if="filters && filters.length > 0">
            <div class="filters-header">فیلترهای اعمال‌شده:</div>
            <div class="filters-tags">
              <div
                class="filter-tag"
                v-for="(item, index) in filters"
                :key="index"
              >
                <span>{{ item.title }}</span>
                <i class="fa fa-remove" @click="removeFilter(item.slug)"></i>
              </div>
            </div>
          </div>
          <!-- filters -->
        </div>

        <!-- common -->
        <div class="common-filter-box col-xl-9 col-lg-8">
          <button
            class="info-btn d-lg-none"
            @click="enableFilters = !enableFilters"
          >
            جست‌وجوی پیشرفته
          </button>
          <div class="input-group ml-3 mr-3">
            <label>مرتب‌سازی:</label>
            <select
              class="form-control mr-2"
              v-model="sort"
              @change="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      sort: sort
                    }
                  })
                  .catch(() => {})
              "
            >
              <option value="">پیش‌فرض</option>
              <option value="seen">پربازدیدترین</option>
              <option value="sell">پرفروش‌ترین</option>
              <option value="created">تاریخ ورود</option>
              <option value="republish">تاریخ تجدید چاپ</option>
              <option value="name">حروف الفبا</option>
              <option value="cheap">ارزان‌ترین</option>
              <option value="rich">گران‌ترین</option>
            </select>
          </div>
          <div class="input-group ml-3 mr-3">
            <label>تعداد:</label>
            <select
              class="form-control mr-2"
              v-model="count"
              @change="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      count: count
                    }
                  })
                  .catch(() => {})
              "
            >
              <option value="32">32</option>
              <option value="64">64</option>
              <option value="96">96</option>
            </select>
          </div>
          <div class="input-group ml-3 mr-3">
            <label>کتاب‌های موجود</label>
            <toggle-button
              :value="isExist"
              v-model="isExist"
              class="mr-2"
              :width="80"
              :font-size="14"
              :height="30"
              color="#25a015"
              :sync="true"
              @change="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      isExist: isExist
                    }
                  })
                  .catch(() => {})
              "
              :labels="{ checked: 'بله', unchecked: 'خیر' }"
            />
          </div>
          <div class="input-group">
            <p style="white-space: break-spaces; margin-bottom: 0">
              تعداد نتایج پیدا شده: &nbsp;{{ total }}
            </p>
          </div>
          <div class="input-group">
            <p style="white-space: break-spaces; margin-bottom: 0">
              تعداد کل صفحات: &nbsp;{{ pages }}
            </p>
          </div>
        </div>
        <!-- common -->
      </div>

      <div class="row">
        <!-- filter box -->
        <div
          class="filter-container col-xl-3 col-lg-4"
          v-bind:class="{ active: enableFilters }"
        >
          <button
            class="info-btn d-lg-none mt-3 mb-3"
            @click="enableFilters = !enableFilters"
          >
            بستن
            <i class="fa fa-close"></i>
          </button>

          <Collapsible title="جست‌وجو در نتایج: " status="active">
            <!-- search box -->
            <div
              class="
                search-custom-container
                d-flex
                justify-content-between
                mt-3
                mb-3
              "
            >
              <input
                placeholder="نام کتاب مورد نظر را جست‌وجو کنید!"
                v-model="q"
                @keypress="
                  $event.keyCode === 13
                    ? $router
                        .push({
                          query: {
                            ...$route.query,
                            q: q
                          }
                        })
                        .catch(() => {})
                    : ''
                "
              />
              <i
                class="fa fa-search icon-search"
                @click="
                  $router
                    .push({
                      query: {
                        ...$route.query,
                        q: q
                      }
                    })
                    .catch(() => {})
                "
              ></i>
            </div>
            <!-- search box -->
          </Collapsible>

          <Collapsible title="بازه‌ی قیمت (ریال):" status="active">
            <!-- price slider -->
            <div class="slider-container mt-3 mb-3">
              <vue-slider
                v-model="price_range"
                :min="0"
                :max="100000000"
                tooltip="always"
                :tooltip-formatter="val => new Intl.NumberFormat().format(val)"
                style="width: 100%"
              />
              <button
                class="submit-btn apply ml-auto mr-auto"
                @click="
                  $router
                    .push({
                      query: {
                        ...$route.query,
                        range_start: price_range[0],
                        range_end: price_range[1]
                      }
                    })
                    .catch(() => {})
                "
              >
                اعمال
              </button>
            </div>
            <!-- price slider -->
          </Collapsible>

          <!-- brand -->
          <multiselect
            v-model="brand"
            placeholder="انتخاب ناشر"
            label="name"
            track-by="id"
            :options="brands"
            :multiple="true"
            :show-labels="false"
            @input="
              $router
                .push({
                  query: { ...$route.query, brand: JSON.stringify(brand) }
                })
                .catch(() => {})
            "
          >
            <span slot="noResult">ناشر پیدا نشد</span>
          </multiselect>
          <!-- brand -->
          <br />
          <!-- author -->
          <multiselect
            v-model="author"
            placeholder="انتخاب نویسنده"
            label="name"
            track-by="id"
            :options="authors"
            :multiple="true"
            :show-labels="false"
            @input="
              $router
                .push({
                  query: { ...$route.query, author: JSON.stringify(author) }
                })
                .catch(() => {})
            "
          >
            <span slot="noResult">نویسنده پیدا نشد</span>
          </multiselect>
          <!-- author -->
          <br />
          <!-- translator -->
          <multiselect
            v-model="translator"
            placeholder="انتخاب مترجم"
            label="name"
            track-by="id"
            :options="translators"
            :multiple="true"
            :show-labels="false"
            @input="
              $router
                .push({
                  query: {
                    ...$route.query,
                    translator: JSON.stringify(translator)
                  }
                })
                .catch(() => {})
            "
          >
            <span slot="noResult">مترجم پیدا نشد</span>
          </multiselect>
          <!-- translator -->

          <!-- search button -->
          <!-- <div style="width: 150px; text-align: center">
            <button v-if="!advanced" class="submit-btn" @click="getProducts">
              جست‌وجو
            </button>
          </div> -->
          <!-- search button -->

          <!--advanced search button -->
          <div class="advanced-search-button mt-5">
            <hr />
            <button
              data-toggle="collapse"
              data-target="#advanced-filter-box"
              ref="advancedSearch"
            >
              جست‌وجوی پیشرفته
            </button>
            <!-- <button  @click="toggleAdvanced">جست‌وجوی پیشرفته</button> -->
          </div>
          <!-- advanced search button -->

          <!-- advanced filter box -->
          <!-- <div id="advanced-filter-box" class=""> -->
          <div id="advanced-filter-box" class="collapse">
            <br />

            <!-- topics -->
            <!-- <multiselect
              v-model="topic"
              placeholder="انتخاب موضوع"
              label="main_topic"
              track-by="main_topic"
              :options="topics"
              :multiple="true"
              :show-labels="false"
              @input="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      topic: JSON.stringify(topic)
                    }
                  })
                  .catch(() => {})
              "
            >
              <span slot="noResult">موضوع پیدا نشد</span>
            </multiselect> -->
            <!-- topics -->

            <br />
            <!-- book size -->
            <multiselect
              v-model="book_size"
              placeholder="انتخاب قطع"
              label="book_size"
              track-by="book_size"
              :options="bookSizes"
              :multiple="true"
              :show-labels="false"
              @input="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      book_size: JSON.stringify(book_size)
                    }
                  })
                  .catch(() => {})
              "
            >
              <span slot="noResult">قطع پیدا نشد</span>
            </multiselect>
            <!-- book size -->

            <br />
            <!-- cover type -->
            <multiselect
              v-model="cover_type"
              placeholder="انتخاب نوع جلد"
              label="cover_type"
              track-by="cover_type"
              :options="coverTypes"
              :multiple="true"
              :show-labels="false"
              @input="
                $router
                  .push({
                    query: {
                      ...$route.query,
                      cover_type: JSON.stringify(cover_type)
                    }
                  })
                  .catch(() => {})
              "
            >
              <span slot="noResult">نوع جلد پیدا نشد</span>
            </multiselect>
            <!-- cover type -->

            <!-- page -->
            <Collapsible title="صفحات" status="">
              <div class="custom-row">
                <!-- <label class="ml-3 mt-2"> صفحات</label> -->
                <div class="custom-input-group">
                  از:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="page_start"
                  />
                </div>
                <div class="custom-input-group">
                  تا:
                  <input class="input mr-1" type="number" v-model="page_end" />
                </div>
                <button
                  class="submit-btn apply"
                  @click="
                    $router
                      .push({
                        query: {
                          ...$route.query,
                          page_start: page_start,
                          page_end: page_end
                        }
                      })
                      .catch(() => {})
                  "
                >
                  اعمال
                </button>
              </div>
            </Collapsible>
            <!-- page -->

            <!-- weight -->
            <Collapsible title="وزن (گرم)" status="">
              <div class="custom-row">
                <!-- <label class="ml-3 mt-2"> وزن</label> -->
                <div class="custom-input-group">
                  از:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="weight_start"
                  />
                </div>
                <div class="custom-input-group">
                  تا:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="weight_end"
                  />
                </div>
                <button
                  class="submit-btn apply"
                  @click="
                    $router
                      .push({
                        query: {
                          ...$route.query,
                          weight_start: weight_start,
                          weight_end: weight_end
                        }
                      })
                      .catch(() => {})
                  "
                >
                  اعمال
                </button>
              </div>
            </Collapsible>
            <!-- weight -->

            <!-- publish -->
            <Collapsible title="نوبت چاپ" status="">
              <div class="custom-row">
                <!-- <label class="ml-3 mt-2">نوبت چاپ</label> -->
                <div class="custom-input-group">
                  از:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="published_start"
                  />
                </div>
                <div class="custom-input-group">
                  تا:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="published_end"
                  />
                </div>
                <button
                  class="submit-btn apply"
                  @click="
                    $router
                      .push({
                        query: {
                          ...$route.query,
                          published_start: published_start,
                          published_end: published_end
                        }
                      })
                      .catch(() => {})
                  "
                >
                  اعمال
                </button>
              </div>
            </Collapsible>
            <!-- publish -->

            <!-- publish year -->
            <Collapsible title="سال چاپ" status="">
              <div class="custom-row">
                <!-- <label class="ml-3 mt-2">نوبت چاپ</label> -->
                <div class="custom-input-group">
                  از:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="publish_year_start"
                  />
                </div>
                <div class="custom-input-group">
                  تا:
                  <input
                    class="input mr-1"
                    type="number"
                    v-model="publish_year_end"
                  />
                </div>
                <button
                  class="submit-btn apply"
                  @click="
                    $router
                      .push({
                        query: {
                          ...$route.query,
                          publish_year_start: publish_year_start,
                          publish_year_end: publish_year_end
                        }
                      })
                      .catch(() => {})
                  "
                >
                  اعمال
                </button>
              </div>
            </Collapsible>
            <!-- publish year -->

            <!-- enter year -->
            <Collapsible title="تاریخ ورود به انبار" status="">
              <div v-if="created_at === ''" class="custom-row mt-3 mb-3">
                <div class="custom-input-group">
                  از:
                  <date-picker
                    color="#000"
                    class="ltr mr-1"
                    v-model="created_at_start"
                  ></date-picker>
                </div>
                <div class="custom-input-group">
                  تا:
                  <date-picker
                    color="#000"
                    class="ltr mr-1"
                    v-model="created_at_end"
                  ></date-picker>
                </div>
              </div>
              <button
                class="submit-btn apply ml-auto mr-auto mb-3"
                @click="
                  $router
                    .push({
                      query: {
                        ...$route.query,
                        created_at_start: created_at_start,
                        created_at_end: created_at_end
                      }
                    })
                    .catch(() => {})
                "
              >
                اعمال
              </button>
            </Collapsible>
            <!-- enter year-->

            <!-- <div class="input-group"> -->
            <!-- search button -->
            <!-- <button v-if="advanced" class="submit-btn" @click="getProducts">
                جست‌وجو
              </button> -->
            <!-- search button -->
            <!-- </div> -->
          </div>
          <!-- advanced filter box -->
        </div>
        <!-- filter box -->

        <!-- products -->
        <div class="col-xl-9 col-lg-8">
                                      <!-- pagination -->
          <div class="mt-3 col-md-12 d-flex mi-paginate">
              <paginate
              v-model="current_page"
              :page-count="pages"
              :click-handler="goToPage"
              :prev-text="'&lsaquo;'"
              :next-text="'&rsaquo;'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :container-class="'pagination'"
              :page-class="'page-link'"
              :page-range="5"
              :margin-pages="4"
            >
            </paginate>
          </div>
          <!-- pagination -->
          <!-- product list -->
          <Spinner v-if="loading"></Spinner>

          <items-grid v-else :products="products" type="grid"></items-grid>
          <!-- product list -->

          <!-- pagination -->
          <div v-if="products.length !== 0" class="pagination-container">
            <paginate
              v-model="current_page"
              :page-count="pages"
              :click-handler="goToPage"
              :prev-text="'&lsaquo;'"
              :next-text="'&rsaquo;'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :container-class="'pagination'"
              :page-class="'page-link'"
              :page-range="5"
              :margin-pages="4"
            >
            </paginate>

            <!-- <ul class="pagination mr-2">
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === 1 }"
              >
                <a class="page-link" aria-label="Next" @click="goToPage(1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === 1 }"
              >
                <a class="page-link" aria-label="Previous" @click="goToPrev()">
                  <span aria-hidden="true">&lsaquo;</span>
                </a>
              </li>

              <li class="page-item" v-if="current_page - 1 > 0">
                <a class="page-link" @click="goToPage(current_page - 1)">{{
                  current_page - 1
                }}</a>
              </li>

              <li class="page-item active">
                <a class="page-link" @click="goToPage(current_page)">{{
                  current_page
                }}</a>
              </li>

              <li class="page-item" v-if="current_page + 1 < pages">
                <a class="page-link" @click="goToPage(current_page + 1)">{{
                  current_page + 1
                }}</a>
              </li>
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === pages }"
              >
                <a class="page-link" aria-label="Next" @click="goToNext()">
                  <span aria-hidden="true">&rsaquo;</span>
                </a>
              </li>
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === pages }"
              >
                <a class="page-link" aria-label="Next" @click="goToPage(pages)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul> -->

            <label class="mt-1">تعداد کل صفحات: {{ pages }}</label>
          </div>
          <!-- pagination -->
        </div>
        <!-- products -->
      </div>
    </div>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "../../components/Spinner.component";

import ItemsGrid from "../../components/ItemsGrid.component";
import Collapsible from "../../components/Collapsible.component";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import Paginate from "vuejs-paginate";

export default {
  components: {
    Breadcrumb,
    Spinner,
    Collapsible,
    ItemsGrid,
    VueSlider,
    Multiselect,
    Paginate
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [],
      title: "",
      q: "",
      products: [],
      count: 32,
      pages: 1,
      total: 0,
      current_page: 1,
      brands: [],
      brand: "",
      authors: [],
      author: "",
      translators: [],
      translator: "",
      coverTypes: [],
      cover_type: "",
      bookSizes: [],
      book_size: "",
      isExist: false,
      sort: "",
      price_range: [0, 100000000],
      advanced: false,
      // topics: [],
      // topic: "",
      page_start: "",
      page_end: "",
      weight_start: "",
      weight_end: "",
      published_start: "",
      published_end: "",
      publish_year_start: "",
      publish_year_end: "",
      created_at: "",
      created_at_start: "",
      created_at_end: "",
      filters: [],
      enableFilters: false,
      types: [],
      type: null
    };
  },
  computed: {
    getPages() {
      return this.pages;
    }
  },
  watch: {
    // sort() {
    //   this.getProducts();
    // },
    // count() {
    //   this.getProducts();
    // },
    // exist() {
    //   this.getProducts();
    // },
    "$route.query"() {
      this.getRequierments();
      this.getFilters();
      this.getProducts();
    }
  },
  mounted() {
    //if router has parameters
    this.getFilters();

    //get search products
    this.getProducts();

    //enable loader
    // this.$store.commit("setLoader", true);
    this.getRequierments();
  },
  methods: {
    getRequierments() {
      //get filter requirements
      this.$store
        .dispatch("getCustomFilterRequirement", {
          slug: this.$route.params.slug,
          q: this.q,
          // page: this.current_page,
          // count: this.count,
          isExist: this.isExist ? 1 : 0,
          sort: this.sort,
          brand: this.transform(this.brand, "id"),
          author: this.transform(this.author, "id"),
          translator: this.transform(this.translator, "id"),
          range_start: this.price_range[0],
          range_end: this.price_range[1],
          book_size: this.transform(this.book_size, "book_size"),
          cover_type: this.transform(this.cover_type, "cover_type"),
          // main_topic: this.transform(this.topic, "main_topic"),
          page_start: this.page_start,
          page_end: this.page_end,
          weight_start: this.weight_start,
          weight_end: this.weight_end,
          published_start: this.published_start,
          published_end: this.published_end,
          publish_year_start: this.publish_year_start,
          publish_year_end: this.publish_year_end,
          created_at: this.created_at,
          created_at_start: this.created_at_start,
          created_at_end: this.created_at_end
        })
        .then(result => {
          this.brands = this.objToArr(result.brands, "name");
          this.authors = this.objToArr(result.authors, "name");
          this.translators = this.objToArr(result.translators, "name");
          this.coverTypes = this.objToArr(result.coverTypes, "cover_type");
          this.bookSizes = this.objToArr(result.bookSizes, "book_size");
          this.types = result.types;
          // this.categories = result.categories;
          // this.topics = result.topics;

          //disable loader
          // this.$store.commit("setLoader", false);
        });
    },
    getFilters() {
      this.filters = [];

      if (this.$route.query.q) {
        this.q = this.$route.query.q;
      }
      if (this.$route.query.type) {
        this.type = this.$route.query.type;
        this.checkTypeSelected();
        this.filters.push({ title: "نوع محصول", slug: "type" });
      }

      if (this.$route.query.sort) {
        this.sort = this.$route.query.sort;
      }
      if (this.$route.query.count) {
        this.count = this.$route.query.count;
      }
      if (this.$route.query.isExist) {
        this.isExist = this.$route.query.isExist == "true" ? true : false;
      }

      if (this.$route.query.range_start || this.$route.query.range_end) {
        this.price_range = [];
        this.price_range.push(
          this.$route.query.range_start ? this.$route.query.range_start : 0
        );
        this.price_range.push(
          this.$route.query.range_end ? this.$route.query.range_end : 100000000
        );
        this.filters.push({ title: "بازه‌ی قیمت", slug: "range" });
      }

      if (this.$route.query.brand && this.$route.query.brand !== "[]") {
        this.brand = JSON.parse(this.$route.query.brand);
        this.filters.push({ title: "ناشر", slug: "brand" });
      }

      if (this.$route.query.author && this.$route.query.author !== "[]") {
        this.author = JSON.parse(this.$route.query.author);
        this.filters.push({ title: "نویسنده", slug: "author" });
      }

      if (
        this.$route.query.translator &&
        this.$route.query.translator !== "[]"
      ) {
        this.translator = JSON.parse(this.$route.query.translator);
        this.filters.push({ title: "مترجم", slug: "translator" });
      }

      // if (this.$route.query.topic && this.$route.query.topic !== "[]") {
      //   this.topic = JSON.parse(this.$route.query.topic);
      //   this.filters.push({ title: "موضوع", slug: "topic" });
      // }

      if (this.$route.query.book_size && this.$route.query.book_size !== "[]") {
        this.book_size = JSON.parse(this.$route.query.book_size);
        this.filters.push({ title: "قطع", slug: "book_size" });
      }

      if (
        this.$route.query.cover_type &&
        this.$route.query.cover_type !== "[]"
      ) {
        this.cover_type = JSON.parse(this.$route.query.cover_type);
        this.filters.push({ title: "نوع جلد", slug: "cover_type" });
      }

      if (this.$route.query.page_start || this.$route.query.page_end) {
        this.page_start = this.$route.query.page_start
          ? this.$route.query.page_start
          : "";
        this.page_end = this.$route.query.page_end
          ? this.$route.query.page_end
          : "";
        this.filters.push({ title: "صفحات", slug: "page" });
      }

      if (this.$route.query.weight_start || this.$route.query.weight_end) {
        this.weight_start = this.$route.query.weight_start
          ? this.$route.query.weight_start
          : "";
        this.weight_end = this.$route.query.weight_end
          ? this.$route.query.weight_end
          : "";
        this.filters.push({ title: "وزن", slug: "weight" });
      }

      if (
        this.$route.query.published_start ||
        this.$route.query.published_end
      ) {
        this.published_start = this.$route.query.published_start
          ? this.$route.query.published_start
          : "";
        this.published_end = this.$route.query.published_end
          ? this.$route.query.published_end
          : "";
        this.filters.push({ title: "نوبت چاپ", slug: "published" });
      }

      if (
        this.$route.query.publish_year_start ||
        this.$route.query.publish_year_end
      ) {
        this.publish_year_start = this.$route.query.publish_year_start
          ? this.$route.query.publish_year_start
          : "";
        this.publish_year_end = this.$route.query.publish_year_end
          ? this.$route.query.publish_year_end
          : "";
        this.filters.push({ title: "سال چاپ", slug: "publish_year" });
      }

      if (
        this.$route.query.created_at_start ||
        this.$route.query.created_at_end
      ) {
        this.created_at_start = this.$route.query.created_at_start
          ? this.$route.query.created_at_start
          : "";
        this.created_at_end = this.$route.query.created_at_end
          ? this.$route.query.created_at_end
          : "";
        this.filters.push({ title: "تاریخ ورود", slug: "created_at" });
      }
    },
    checkTypeSelected() {
      if (this.type) {
        if (!this.type.name) {
          this.types.forEach(value => {
            if (this.type === value.name) {
              this.type = value;
            }
          });
        }
      }
    },
    getProducts(page = 1) {
      //enable loader
      this.loading = true;
      this.checkTypeSelected();
      this.$store
        .dispatch("getCategoryProducts", {
          slug: this.$route.params.slug,
          q: this.q,
          page: page,
          count: this.count,
          isExist: this.isExist ? 1 : 0,
          sort: this.sort,
          brand: this.transform(this.brand, "id"),
          author: this.transform(this.author, "id"),
          translator: this.transform(this.translator, "id"),
          range_start: this.price_range[0],
          range_end: this.price_range[1],
          book_size: this.transform(this.book_size, "book_size"),
          cover_type: this.transform(this.cover_type, "cover_type"),
          // main_topic: this.transform(this.topic, "main_topic"),
          page_start: this.page_start,
          page_end: this.page_end,
          weight_start: this.weight_start,
          weight_end: this.weight_end,
          published_start: this.published_start,
          published_end: this.published_end,
          publish_year_start: this.publish_year_start,
          publish_year_end: this.publish_year_end,
          created_at: this.created_at,
          created_at_start: this.created_at_start,
          created_at_end: this.created_at_end
        })
        .then(result => {
          if (!result) {
            this.getFilters();
            //disable loader
            this.$store.commit("setLoader", false);
          } else {
            this.products = result.items.data;
            this.pages = result.latest_page > 0 ? result.latest_page : 1;
            this.current_page =
              result.items.current_page <= this.pages
                ? result.items.current_page
                : this.pages;
            this.total = result.total;
            this.title = result.title;
            this.breadcrumbs = result.breadcrumb;
            this.getFilters();

            //disable loader
            this.loading = false;
          }
        });
    },
    removeFilter(slug) {
      switch (slug) {
        case "range":
          this.price_range = [0, 100000000];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                range_start: null,
                range_end: null
              }
            })
            .catch(() => {});
          break;
        case "brand":
          this.brand = [];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                brand: null
              }
            })
            .catch(() => {});
          break;
        case "author":
          this.author = [];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                author: null
              }
            })
            .catch(() => {});
          break;
        case "translator":
          this.translator = [];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                translator: null
              }
            })
            .catch(() => {});
          break;
        // case "topic":
        //   this.topic = [];
        //   this.$router
        //     .push({
        //       query: {
        //         ...this.$route.query,
        //         topic: null
        //       }
        //     })
        //     .catch(() => {});
        //   break;
        case "book_size":
          this.book_size = [];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                book_size: null
              }
            })
            .catch(() => {});
          break;
        case "cover_type":
          this.cover_type = [];
          this.$router
            .push({
              query: {
                ...this.$route.query,
                cover_type: null
              }
            })
            .catch(() => {});
          break;
        case "page":
          this.page_start = "";
          this.page_end = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                page_start: null,
                page_end: null
              }
            })
            .catch(() => {});
          break;
        case "weight":
          this.weight_start = "";
          this.weight_end = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                weight_start: null,
                weight_end: null
              }
            })
            .catch(() => {});
          break;
        case "published":
          this.published_start = "";
          this.published_end = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                published_start: null,
                published_end: null
              }
            })
            .catch(() => {});
          break;
        case "publish_year":
          this.publish_year_start = "";
          this.publish_year_end = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                publish_year_start: null,
                publish_year_end: null
              }
            })
            .catch(() => {});
          break;
        case "created_at":
          this.created_at_start = "";
          this.created_at_end = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                created_at_start: null,
                created_at_end: null
              }
            })
            .catch(() => {});
          break;
        case "type":
          this.type = "";
          this.$router
            .push({
              query: {
                ...this.$route.query,
                type: null
              }
            })
            .catch(() => {});
          break;
        default:
        // code block
      }
    },
    // toggleAdvanced() {
    //   this.advanced = !this.advanced;
    //   if (!this.advanced) {
    //     //clear advanced search items
    //     this.cover_type = "";
    //     this.book_size = "";
    //     this.topic = "";
    //     this.published_start = "";
    //     this.published_end = "";
    //     this.page_start = "";
    //     this.page_end = "";
    //     this.published_start = "";
    //     this.published_end = "";
    //     this.created_at_start = "";
    //     this.created_at_end = "";
    //     this.weight_start = "";
    //     this.weight_end = "";
    //     this.publish_year_start = "";
    //     this.publish_year_end = "";
    //   }
    // },
    goToPrev() {
      if (this.current_page > 1) {
        this.current_page--;
        this.getProducts(this.current_page);
      }
    },
    goToNext() {
      if (this.current_page < this.pages) {
        this.current_page++;
        this.getProducts(this.current_page);
      }
    },
    goToPage(page) {
      this.current_page = page;
      this.getProducts(this.current_page);
    },
    transform(array, key) {
      let res = [];

      for (let i = 0; i < array.length; i++) {
        res.push(array[i][key]);
      }
      return JSON.stringify(res);
    },
    objToArr(obj, type) {
      let res = [];
      let arr = Object.entries(obj);
      for (let i = 0; i < arr.length; i++) {
        if (type === "name") {
          let temp = {
            id: arr[i][0],
            name: arr[i][1]
          };
          res.push(temp);
        } else if (type === "book_size") {
          let temp = {
            book_size: arr[i][1]
          };
          res.push(temp);
        } else if (type === "cover_type") {
          let temp = {
            cover_type: arr[i][1]
          };
          res.push(temp);
        }
      }
      return res;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>;
<style scoped>
* {
  text-align: right;
  font-size: 14px;
}
.custom-row {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;
}

.custom-input-group {
  display: flex;
  align-items: center !important;
  width: 100% !important;
  /* margin: 1rem; */
  margin-right: 5px;
}

.custom-input-group .input {
  width: 100%;
  height: 30px;
}

.input-group label {
  font-size: 14px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.form-group {
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-wrap: wrap;
}

.form-group label {
  width: 120px;
}

.form-group .input-group {
  margin: 0 0.5rem !important;
}

.category {
  background-color: #ffffff;
}

/*search*/
.search-custom-container {
  position: relative;
  width: 100% !important;
}

.search-custom-container .search {
  width: 100% !important;
}

.search-custom-container input {
  border-radius: 10px;
  padding: 5px 10px;
  text-align: right;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #fff;
  font-size: 12px;
}

.search-custom-container .fa-search {
  font-size: 20px;
  left: 1rem;
  position: absolute;
  top: 30%;
  cursor: pointer;
}

::placeholder {
  color: #6c757d;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d;
}

/* filter */
.filter-box {
  background-color: #e7e7e7;
  padding: 1rem;
  border-radius: 10px;
}

.common-filter-box,
.advanced-filter-box {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;
}

.filter-box .submit-btn {
  height: 50px;
  margin: 0 auto;
}

.ui.selection.dropdown {
  width: 220px;
  text-align: right;
  margin: 1rem;
}

.advanced-search-button {
  position: relative;
}

.advanced-search-button button {
  position: absolute;
  left: calc(50% - 80px);
  top: -20px;
  border: none;
  background-color: #fff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  /* border: 2px solid #000; */
  border-radius: 5px;
}

.advanced-search-button button::after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 25px;
  margin-right: 5px;
}
.selection label {
  width: max-content !important;
}
.ui.selection.dropdown {
  width: 180px !important;
}
.slider-container {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
  /* width: 100px; */
  padding: 0px 35px;
}
.apply {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
/* responsive */
@media screen and (max-width: 750px) {
  .form-group label {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .form-group {
    width: 100%;
  }
}
/* filters */
.filters {
  background-color: #f1f1f1;
  /* padding: 1rem; */
  border-radius: 10px;
  margin: 1rem auto;
  padding: 0;
}
.filters-header {
  background-color: transparent;
  border-bottom: 1px solid #e7e7e7;
  color: #000;
  /* cursor: pointer; */
  padding: 10px;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  text-align: right;
  outline: none;
  font-size: 15px;
}
.filters-tags {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.filter-tag {
  margin: 0.5rem;
  padding: 5px;
  background-color: #e7e7e7;
  border-radius: 5px;
}
.filter-tag i {
  margin: 0 0.5rem;
  cursor: pointer;
}
.common-filter-box .input-group {
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 950px) {
  .common-filter-box {
    flex-wrap: wrap;
  }
  .common-filter-box .input-group {
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-group label {
    margin-bottom: 5px !important;
  }
  .filter-container {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    overflow-y: auto;
    background-color: #e7e7e7;
  }
  .filter-container.active {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .filter-container {
    width: 100%;
  }
}
.pagination {
  margin:auto !important;
  margin-top:2%;
}

</style>
