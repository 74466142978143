import axios from "axios";
import { env } from "../../../enums";

export default {
  namespaced: true, // This is required for module-specific action dispatching
  state: {
    auth: {
      routineLevel: "LOGIN",
      token: null,
    },
    email: "",
    error: "",
    success: "",
  },
  getters: {
    getToken(state) {
      return state.auth.token || localStorage.getItem("token");
    },
  },
  mutations: {
    setUPAuthRoutineLevel(state, data) {
      state.auth.routineLevel = data.status || "";
      state.auth.token = data.token || null;
      if (data.token) {
        localStorage.setItem("token", data.token);
      }
    },
    setFailedEvent(state, message) {
      state.error = message || "An unexpected error occurred.";
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await axios.post(`${env.base_url}/authUP/login`, {
          email,
          password,
        });
        if (response.status === 200) {
          commit("setUPAuthRoutineLevel", { token: response.data.token });
          return true;
        } else {
          commit("setFailedEvent", "Login failed.");
          return false;
        }
      } catch (error) {
        commit(
            "setFailedEvent",
            error.response?.data?.message || "Login failed."
        );
        return false;
      }
    },
  },
};
