<template>
  <section>
    <breadcrumb current-page-name="ورود یا ثبت‌نام"></breadcrumb>
    <div class="wrapper">
      <!-- Tab Navigation -->
      <div class="tabview">
        <button
            :class="{ active: activeTab === 'mobile' }"
            @click="setTab('mobile')"
        >
          ورود با موبایل
        </button>
        <button
            :class="{ active: activeTab === 'email' }"
            @click="setTab('email')"
        >
          ورود با کد پرسنلی
        </button>

      </div>

      <!-- Tab Content -->
      <div class="tab-content">
        <!-- Email Login Flow -->
        <div v-if="activeTab === 'email'">

          <EmailRegister
              v-if="$store.getters.authRoutineLevel === 'REGISTER'"
          />
          <EmailLogin v-if="$store.getters.authRoutineLevel === 'LOGIN'" />
          <EmailForgot
              v-if="$store.getters.authRoutineLevel === 'FORGOT'"
          />
          <EmailVerify
              v-if="$store.getters.authRoutineLevel === 'VERIFY'"
          />
          <EmailWelcome
              v-if="$store.getters.authRoutineLevel === 'WELCOME'"
          />
        </div>

        <!-- Mobile Login Flow -->
        <div v-if="activeTab === 'mobile'">
          <MobileLogin
              v-if="$store.getters.authRoutineLevel === 'LOGIN'"
          />
          <MobileVerify
              v-if="$store.getters.authRoutineLevel === 'VERIFY'"
          />
          <MobileWelcome
              v-if="$store.getters.authRoutineLevel === 'WELCOME'"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb.component";

// Email Components
import EmailRegister from "@/components/auth-up/register.component";
import EmailLogin from "@/components/auth-up/login.component";
import EmailForgot from "@/components/auth-up/forgot.component";
import EmailVerify from "@/components/auth-up/verify.component";
import EmailWelcome from "@/components/auth-up/welcome.component";

// Mobile Components
import MobileLogin from "@/components/auth-otp/login.component";
import MobileVerify from "@/components/auth-otp/verify.component";
import MobileWelcome from "@/components/auth-otp/welcome.component";

export default {
  data: function () {
    return {
      activeTab: "mobile", // مقدار پیش‌فرض
      activeSection: "LOGIN"
    };
  },
  components: {
    Breadcrumb,
    // Email Components
    EmailRegister,
    EmailLogin,
    EmailForgot,
    EmailVerify,
    EmailWelcome,
    // Mobile Components
    MobileLogin,
    MobileVerify,
    MobileWelcome,
  },
  methods: {
    setTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  display: block;
}

.tabview {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabview button {
  flex: 1;
  padding: 10px;
  background: #f5f5f5;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
}

.tabview button.active {
  background: #ffffff;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.tab-content {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
