<template>
  <section>
    <Breadcrumb
      current-page-name="جزئیات آدرس"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>
    <div class="custom-container">
      <div class="row justify-content-center">
        <Spinner v-if="loading"> </Spinner>

        <div v-else class="col-lg-8 customer-panel-box text-right">
          <div class="row">
            <!-- fullname -->
            <div class="col-xl-4">
              <label>نام و نام خانوادگی</label>
              <input
                class="input"
                v-model="fullname"
                type="text"
                ref="fullname"
                placeholder="نام و نام خانوادگی"
              />
            </div>
            <!-- fullname -->

            <!-- email -->
            <div class="col-xl-4">
              <label>آدرس ایمیل</label>
              <input
                class="input"
                v-model="email"
                type="email"
                placeholder="آدرس ایمیل"
                ref="email"
              />
            </div>
            <!-- email -->

            <!-- birthDate -->
            <div class="col-xl-4">
              <label>تاریخ تولد</label>
              <VuePersianDatetimePicker
                display-format="jYYYY/jMM/jDD"
                format="YYYY-MM-DD HH:mm"
                v-model="birthDate"
              ></VuePersianDatetimePicker>
            </div>
            <!-- birthDate -->
          </div>

          <div class="row">
            <!-- province -->
            <div class="col-xl-4">
              <label>استان</label>
              <select @change="getCities()" class="input" v-model="province">
                <option
                  v-for="province in provinces"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
            </div>
            <!-- province -->

            <!-- city -->
            <div class="col-xl-4">
              <label>شهر</label>
              <div style="position: relative">
                <!-- city loading -->
                <div
                  v-if="cityLoading"
                  class="
                    input
                    d-flex
                    align-items-center
                    justify-content-center
                    counter-loader
                  "
                >
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <!-- city loading -->
                <select v-else class="input" v-model="city">
                  <option
                    v-for="item in cities"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- city -->

            <!-- postalCode -->

            <div class="col-xl-4">
              <label>کد پستی ده رقمی</label>
              <input
                class="input mb-0"
                ref="postalCode"
                v-model="postalCode"
                type="text"
                pattern="\d*"
                maxlength="10"
                placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <span
                v-if="postalCode && postalCode.length !== 10"
                style="color: red"
                >کد پستی باید ده رقم باشد</span
              >
            </div>
          </div>
          <!-- postalCode -->

          <br />

          <!-- address -->
          <div class="row">
            <div class="col-xl-12">
              <label>نشانی پستی</label>
              <input
                class="input mb-0"
                type="text"
                v-model="address"
                ref="address"
                placeholder=" "
              />
              <span v-if="address && address.length <= 5" style="color: red"
                >آدرس کوتاه است</span
              >
            </div>
          </div>
          <!-- address -->

          <!-- extra -->
          <div class="row">
            <div class="col-xl-12" style="margin-top: 20px">
              <input
                v-model="sendSelf"
                type="checkbox"
                id="self"
                value="true"
              />
              &nbsp;
              <label for="self"
                >گیرنده سفارش خودم هستم ( کدملی ، شماره تماس نیاز نیست )</label
              >
            </div>
          </div>
          <div v-if="!sendSelf" class="row">
            <!-- <div class="col-xl-4">
              <input
                class="input"
                v-model="info.fullname"
                type="text"
                ref="fullname"
                placeholder="نام و نام خانوادگی"
              />
            </div> -->
            <div class="col-xl-4">
              <input
                class="input"
                v-model="info.phone"
                type="text"
                placeholder="شماره موبایل"
                ref="phone"
                pattern="\d*"
                maxlength="11"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <span
                v-if="!sendSelf && info.phone && info.phone.length !== 11"
                style="color: red"
                >شماره تلفن باید 11 رقم باشد</span
              >
            </div>
            <div class="col-xl-4">
              <input
                class="input"
                v-model="info.meliCode"
                type="text"
                placeholder="کد ملی"
                ref="meliCode"
                pattern="\d*"
                maxlength="10"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
              <span
                v-if="!sendSelf && info.meliCode && info.meliCode.length !== 10"
                style="color: red"
                >کد ملی باید ده رقم باشد</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12" style="text-align: left">
              <input
                class="submit-btn"
                @click="updateAddress()"
                type="button"
                value="ثبت آدرس"
              />
            </div>
          </div>
          <!-- extra -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: { Breadcrumb, Spinner, VuePersianDatetimePicker },
  data() {
    return {
      loading: true,
      cityLoading: false,
      item: {},
      province: "",
      city: "",
      address: "",
      postalCode: "",
      fullname: "",
      sendSelf: false,
      birthDate: "",
      email: "",
      info: {
        meliCode: "",
        phone: ""
      },
      provinces: [],
      cities: []
    };
  },
  mounted() {
    this.$store.dispatch("getProvince").then(result => {
      this.provinces = result;
      this.$store
        .dispatch("getCity", { province: this.province })
        .then(result => {
          this.cities = result;
          this.$store
            .dispatch("editAddress", { id: this.$route.params.id })
            .then(result => {
              this.fullname = result.fullname;
              this.email = result.email;
              this.birthDate = result.birth_date;
              this.address = result.address;
              this.postalCode = result.postalcode;
              this.province = result.state_id;
              this.city = result.city_id;
              // this.info.fullname = result.fullname;
              this.info.meliCode = result.melicode;
              this.info.phone = result.phone;
              this.sendSelf =
                !result.fullname && result.melicode && !result.phone
                  ? true
                  : false;

              this.loading = false;
            });
        });
    });
  },
  methods: {
    getCities() {
      this.cityLoading = true;
      this.$store
        .dispatch("getCity", { province: this.province })
        .then(result => {
          this.cities = result;
          this.cityLoading = false;
        });
    },
    updateAddress() {
      if (!this.fullname) {
        this.$refs.fullname.focus();
        return;
      }
      if (!this.email) {
        this.$refs.email.focus();
        return;
      }

      if (!this.city) {
        this.$swal.fire({
          position: "center",
          icon: "error",
          title: "لطفا شهر خود را وارد کنید !",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      if (!this.postalCode) {
        this.$refs.postalCode.focus();
        return;
      }

      if (!this.address) {
        this.$refs.address.focus();
        return;
      }

      if (this.postalCode.length !== 10) {
        this.$refs.postalCode.focus();
        return;
      }
      if (this.address.length <= 5) {
        this.$refs.address.focus();
        return;
      }

      if (!this.sendSelf && !this.info.phone) {
        this.$swal.fire({
          position: "center",
          icon: "error",
          title: "لطفا شماره تلفن خود را وارد کنید !",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }
      // if (!this.sendSelf && this.info.fullname.length < 1) {
      //   this.$refs.fullname.focus();
      //   return;
      // }
      // if (!this.sendSelf && this.info.meliCode.length !== 10) {
      //   this.$refs.meliCode.focus();
      //   return;
      // }
      if (!this.sendSelf && this.info.phone.length !== 11) {
        this.$refs.phone.focus();
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("updateAddress", {
          id: this.$route.params.id,
          data: {
            fullname: this.fullname,
            email: this.email,
            birthDate: this.birthDate,
            city: this.city,
            province: this.province,
            address: this.address,
            postalCode: this.postalCode,
            sendSelf: this.sendSelf,
            info: {
              // fullname: this.info.fullname,
              meliCode: this.info.meliCode,
              phone: this.info.phone
            }
          }
        })
        .then(result => {
          if (result.success) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "آدرس با موفقیت ویرایش شد !",
              showConfirmButton: false,
              timer: 1500
            });
            if (this.$route.query.isCart) {
              this.$router.push({ name: "cartAddress" });
            } else {
              this.$router.push({ name: "addresses" });
            }
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "مشکلی در ویرایش آدرس رخ داده است !",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
select.input {
  height: 45px;
}
</style>
