<template>
  <section class="mt-5">
    <!-- overview -->
    <div v-show="product.body">
      <h6 class="text-right">شرح محصول</h6>
      <div class="overview-box">
        <p v-html="product.body"></p>
      </div>
    </div>
    <!-- overview -->
    <div class="d-flex w-100 text-right">
      <ul class="more-detail">
        <li
          @click="changeTab('properties')"
          :class="'product-property ' + propertiesTab"
        >
          ویژگی‌های کالا
        </li>
        <li
          @click="changeTab('attribute')"
          :class="'technical-property ' + attributeTab"
        >
          مشخصات
        </li>
        <!-- <li
          @click="changeTab('content')"
          :class="'product-content ' + contentTab"
        >
          توضیحات
        </li> -->
        <li
          v-if="product.author_info && product.author_info.about"
          @click="changeTab('AbouttheAuthor')"
          :class="'comment ' + AbouttheAuthorTab"
        >
          درباره نویسنده
        </li>
      </ul>
    </div>
    <hr id="line" />
    <div v-if="tab === 'properties'" class="properties-container">
      <ul>
        <li class="d-flex justify-content-center">
          <label>کد اصلی:</label>
          <p style="white-space: break-spaces;margin:0">
            {{ publish.main_code }}
          </p>
        </li>
        <li>
          <label>موضوع:</label>
          <router-link
            :to="
              '/category/' + (category && category.slug ? category.slug : '')
            "
          >
            {{ category && category.name ? category.name : "" }}
          </router-link>
        </li>
        <li>
          <label>وزن:</label>
          {{ product.weight }}
        </li>
        <li>
          <label>صفحه:</label>
          {{ product.page_count }}
        </li>
        <!-- <li>
          <label>تیراژ :</label>
          {{ product.circulation }}
        </li> -->
        <!-- <li>
          <label>نوع کتاب :</label>
          {{ product.book_type }}
        </li> -->
        <!-- <li>
        <label>نویسنده:</label>
        {{ product.author }}
        </li> -->
      </ul>
    </div>
    <div v-if="tab === 'content'" v-html="product.body" class="content"></div>
    <div v-if="tab === 'attribute'">
      <div
        v-for="(attribute, index) in product.attributes"
        :key="attribute[index]"
        class="attribute-container"
      >
        <div class="attribute-key">
          {{ attribute.key }}
        </div>
        <div class="attribute-value">
          {{ attribute.value }}
        </div>
      </div>
      <br />
      <div v-for="(attr, index) in product.dynamicAttributes" :key="index">
        <div v-if="attr.quantity > 0" class="publish-type">
          <hr v-if="index > 1" />
          <span>نوبت چاپ: {{ attr.publish }}</span>
        </div>
        <ul v-if="attr.quantity > 0" class="properties-container">
          <li>
            <label>نوع جلد:</label>
            {{ attr.cover_type }}
          </li>
          <li>
            <label>قطع:</label>
            {{ attr.book_size }}
          </li>
          <li>
            <label>سال چاپ:</label>
            {{ attr.publish_year }}
          </li>
          <li>
            <label>شابک:</label>
            {{ attr.isbn }}
          </li>
          <li>
            <label>تاریخ ورود:</label>
            {{ attr.created_at | moment("jYYYY-jMM-jDD") }}
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="
        product.author_info &&
          product.author_info.about &&
          tab === 'AbouttheAuthor'
      "
      align="center"
    >
      <br />
      <h3>درباره نویسنده</h3>
      <p>{{ product.author_info.about }}</p>
    </div>

    <div id="customer-reviews">
      <div class="publish-type">
        <hr class="hr-margin" />
        <span>نظرات مشتریان</span>
        <Comment :comments="product.comments" :productId="product.id"></Comment>
      </div>
    </div>
  </section>
</template>
<script>
import Comment from "@/components/comment.component.vue";

export default {
  name: "ProductContent",
  props: ["product", "publish", "category"],
  components: { Comment },
  data() {
    return {
      tab: "properties",
      propertiesTab: "show",
      contentTab: "hide",
      attributeTab: "hide",
      AbouttheAuthorTab: "hide"
    };
  },
  methods: {
    changeTab: function(tab) {
      this.tab = tab;
      if (tab === "properties") {
        this.propertiesTab = "show";
        this.contentTab = "hide";
        this.attributeTab = "hide";
        this.AbouttheAuthorTab = "hide";
      }
      if (tab === "content") {
        this.contentTab = "show";
        this.attributeTab = "hide";
        this.commentTab = "hide";
        this.propertiesTab = "hide";
	this.AbouttheAuthorTab = "hide";
      }


      if (tab === "attribute") {
        this.contentTab = "hide";
        this.attributeTab = "show";
        this.AbouttheAuthorTab = "hide";
        this.propertiesTab = "hide";
      }
      if (tab === "AbouttheAuthor") {
 this.contentTab = "hide";
        this.attributeTab = "hide";
        this.AbouttheAuthorTab = "show";
        this.propertiesTab = "hide";
      }
    }
  }
};
</script>
<style scoped>
.more-detail {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
}

.more-detail li {
  border-right: 0.5px solid #1c403e70;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  color: #1c403e !important;
}

#line {
  margin: 0 !important;
}

.more-detail li.show {
  background-color: #ffffff;
  border-top: 0.5px solid #1c403e70;
  border-left: 0.5px solid #1c403e70;
}
.more-detail li.comment.show {
  border-left: 0.5px solid #1c403e70;
}

.more-detail li.hide {
}

.attribute-container {
  display: flex;
  height: 40px;
  margin: 0.5rem auto;
}

.attribute-key,
.attribute-value {
  padding: 0.5rem 3rem;
}

.attribute-key {
  background-color: #e7e7e7;
  width: 300px;
  text-align: right;
}

.attribute-value {
  width: 100%;
  text-align: right;
  background-color: #fafafa;
}

.properties-container ul {
  list-style: none;
}
.overview-box {
  width: 100%;
  height: auto;
  /* border: 1px solid #000; */
  border-radius: 5px;
  padding: 1rem;
  text-align: right;
  margin-bottom: 3rem;
}
.properties-container {
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  list-style: none;
}

.properties-container li {
  margin-bottom: 0.5rem;
}

.properties-container li label::before {
  content: "\f111";
  font-family: FontAwesome;
  font-size: 12px;
  margin-left: 0.5rem;
}
.publish-type {
  position: relative;
}
.publish-type span {
  background-color: #fff;
  padding: 0.5rem;
  height: 40px;
  width: 120px;
  position: absolute;
  top: -20px;
  border-radius: 5px;
  left: calc(50% - 60px);
  text-align: center;
}
.hr-margin {
  margin-top: 3rem !important;
}
.content {
  padding: 1rem;
  line-height: 2;
}

.content img {
  max-width: 100%;
}

@media screen and (max-width: 550px) {
  .more-detail li {
    margin: 0.25rem;
  }
}
</style>
