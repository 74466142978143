import Product from "../../views/pages/Product.vue";
import ProductSelective from "../../views/pages/ProductSelective";
export default [
  {
    path: "/product/:slug",
    name: "ProductInfo",
    component: Product
  },
  {
    path: "/product-selective/:type",
    name: "productSelective",
    component: ProductSelective
  }
];
