<template>
  <section>
    <Breadcrumb
      current-page-name="مشاهده سفارش"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>

    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>

        <div class="col-lg-8 customer-panel-box">
          <Spinner v-if="loading"> </Spinner>

          <div v-else class="custom-table-container">
            <table class="custom-table">
              <thead>
                <tr class="h">
                  <th>تصویر</th>
                  <th>عنوان</th>
                  <th>ناشر</th>
                  <th>نویسنده</th>
                  <th>تعداد</th>
                  <th>نوبت چاپ</th>
                  <th>مبلغ واحد</th>
                  <th>مبلغ کل</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>
                    <img
                      width="50px"
                      :src="product.image"
                      alt="img-product"
                      @error="product.image = '/template/images/no-image.png'"
                    />
                  </td>
                  <td>
                    <router-link
                      tag="a"
                      value="مشاهده جزئیات محصول"
                      type="button"
                      :to="'/product/' + product.slug"
                      >{{ product.name }}</router-link
                    >
                  </td>
                  <td>{{ product.brand }}</td>
                  <td>{{ product.author }}</td>
                  <td>{{ product.qty }}</td>
                  <td>{{ product.option }}</td>
                  <td>
                    {{ new Intl.NumberFormat().format(product.price) }} ریال
                  </td>
                  <td>
                    {{
                      new Intl.NumberFormat().format(
                        Number(product.qty) * Number(product.price)
                      )
                    }}
                    ریال
                  </td>
                </tr>

                <!-- order details -->
                <tr class="table-footer">
                  <td colspan="1">
                    <p>شماره سفارش</p>
                    <p>{{ "PAKAT_" + order.id }}</p>
                  </td>
                  <td colspan="1">
                    <p>وضعیت سفارش</p>
                    <p>
                      {{
                        order.status == "lost"
                          ? "لغو شده"
                          : order.status == "accepted"
                          ? "تایید شده"
                          : order.status == "delivery"
                          ? "ارسال شده"
                          : "در حال بررسی"
                      }}
                    </p>
                  </td>
                  <td colspan="1">
                    <p>روش ارسال</p>
                    <p>{{ shipping_name }}</p>
                  </td>
                  <td colspan="1">
                    <p>قیمت نهایی</p>
                    <p>
                      {{
                        new Intl.NumberFormat().format(order.total_price)
                      }}ریال
                    </p>
                  </td>
                  <td colspan="4">
                    <p>توضیحات سفارش</p>
                    <p style="text-align: justify">{{ order.description }}</p>
                  </td>
                  <td v-if="order.track_code" colspan="4">
                    <p>کد پیگیری پستی</p>
                    <p style="text-align: justify">{{ order.track_code }}</p>
                  </td>
                </tr>
                <!-- order details -->
              </tbody>
            </table>
          </div>

          <router-link
            v-if="!loading"
            type="button"
            target="_blank"
            :to="'/profile/orders/invoice/' + order.id"
            class="info-btn"
          >
            فاکتور سفارش
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-order-show",
  data() {
    return {
      order: {},
      products: [],
      shipping_name: "",
      loading: false
    };
  },
  components: { SideMenu, Breadcrumb, Spinner },
  methods: {},
  created() {
    this.loading = true;

    this.$store
      .dispatch("getOrderInfo", { orderId: this.$route.params.id })
      .then(result => {
        this.order = result.order;
        this.products = result.products;
        this.shipping_name = result.shipping_name;
        this.loading = false;
      });
  }
};
</script>

<style scoped>
.table-footer {
  border-top: 1px solid #beb1b1;
}
.table-footer tr {
  border-bottom: 1px solid #e0e0e0;
}

.table-footer td {
  vertical-align: baseline;
}
.table-footer td > p {
  min-height: 30px;
}

.table-footer td > p:first-child {
  background-color: #1c403e;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  min-width: max-content;
}
</style>
