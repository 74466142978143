<template>
  <div v-if="products.length === 0" class="no-result">نتیجه‌ای یافت نشد!</div>
  <div v-else>
    <ul v-if="type === 'grid'" class="grid-items">
      <li v-for="(product, index) in products" :key="product[index]">
        <ProductItem :product="product"></ProductItem>
      </li>
    </ul>
    <ul v-else class="list-items">
      <li v-for="(product, index) in products" :key="product[index]">
        <ProductItem :product="product"></ProductItem>
      </li>
    </ul>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem.component";
export default {
  props: ["products", "type"],
  components: {
    ProductItem
  }
};
</script>

<style scoped>
.product-item {
  width: 250px;
  text-align: center;
  border: 1px solid #efefef;
  margin: 5px;
  min-height: 410px;
}

.product-item:hover {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.21);
  transition: 0.3s ease-in-out;
}
.product-title {
  display: block;
  color: #1c403e;
  text-align: center;
  width: 250px;
  max-height: 43px;
  overflow: hidden;
  font-weight: bold;
}
.product-author {
  display: block;
  color: #122c2b;
  text-align: center;
}
.product-title:hover {
  color: #1e857f;
}
.price {
  margin: 5px;
}

.buy_btn {
  border: 2px solid #007bff;
  color: #007bff;
  margin: 17px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.buy_btn:hover {
  border: 2px solid #007bff83;
  color: #007bff83;
}

.no-result {
  color: #6c757d;
  text-align: center;
  margin: 2rem auto;
}
.grid-items {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem auto;
}

.grid-items li {
  cursor: pointer;
  margin: 1rem auto;
  width: 250px;
  height: 475px;
  position: relative;
  display: contents;
}
.grid-items li a {
  display: block;
  justify-content: center;
  align-items: center;
}
.grid-items li img {
  width: 200px;
  margin: 1rem auto;
}

.show-product {
  margin-top: 1rem;
}

.show-product:hover {
  color: #000000 !important;
}

.show-product hr {
  visibility: hidden;
  margin: 5px auto !important;
  border-color: black;
}

.show-product:hover hr {
  visibility: visible;
  animation: showProductAnim 0.5s forwards;
}

@keyframes showProductAnim {
  from {
    width: 0px;
  }
  to {
    width: 110px;
  }
}

.grid-items li:hover {
}

.product-details-container {
  width: 100%;
  text-align: center;
  background-color: #fff;
}

.grid-items li:hover .product-details-container {
  animation: showDetailsAnim 0.5s forwards;
}

@keyframes showDetailsAnim {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: -100px;
  }
}

.img_fit {
  object-fit: contain;
  height: 250px;
}
</style>
