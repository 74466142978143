import axios from "axios";
import { env } from "../../../enums";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSuggestedProducts(context, { q }) {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + `/search?short=false${q ? "&q=" + q : ""}`)
          .then(result => {
            if (result.status === 200) {
              resolve(result.data.items);
            }
          });
      });
    },
    getSearchedProducts(
      context,
      {
        api,
        q,
        page,
        count,
        category,
        isExist,
        sort,
        brand,
        author,
        translator,
        range_start,
        range_end,
        book_size,
        cover_type,
        main_topic,
        page_start,
        page_end,
        weight_start,
        weight_end,
        published_start,
        published_end,
        publish_year_start,
        publish_year_end,
        created_at,
        created_at_start,
        created_at_end,
        type
      }
    ) {
      var query =
        env.base_url +
        `/search${page ? "?page=" + page : ""}${api ? "&api=" + api : ""}${
          q ? "&q=" + q : ""
        }${count ? "&count=" + count : ""}${
          category ? "&category=" + category : ""
        }${isExist ? "&isExist=" + isExist : ""}${sort ? "&sort=" + sort : ""}${
          brand ? "&brand=" + brand : ""
        }${author ? "&author=" + author : ""}${
          translator ? "&translator=" + translator : ""
        }${range_start ? "&range_start=" + range_start : ""}${
          range_end && range_end < 100000000 ? "&range_end=" + range_end : ""
        }${book_size ? "&book_size=" + book_size : ""}${
          cover_type ? "&cover_type=" + cover_type : ""
        }${main_topic ? "&main_topic=" + main_topic : ""}${
          page_start ? "&page_start=" + page_start : ""
        }${page_end ? "&page_end=" + page_end : ""}${
          weight_start ? "&weight_start=" + weight_start : ""
        }${weight_end ? "&weight_end=" + weight_end : ""}${
          published_start ? "&published_start=" + published_start : ""
        }
      ${published_end ? "&published_end=" + published_end : ""}${
          publish_year_start ? "&publish_year_start=" + publish_year_start : ""
        }${publish_year_end ? "&publish_year_end=" + publish_year_end : ""}${
          created_at ? "&created_at=" + created_at : ""
        }${created_at_start ? "&created_at_start=" + created_at_start : ""}${
          created_at_end ? "&created_at_end=" + created_at_end : ""
        }${type ? "&type=" + type : ""}`;
      let url = query + "&api=true";
      return new Promise(function(resolve) {
        axios
          .get(query)
          .then(result => {
            if (result.status === 200) {
              resolve({ ...result.data, url });
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    // getSearchedProducts(
    //   context,
    //   {
    //     q,
    //     page,
    //     count,
    //     type,
    //     category,
    //     isExist,
    //     sort,
    //     brand,
    //     author,
    //     translator,
    //     range_start,
    //     range_end,
    //     book_size,
    //     cover_type,
    //     // main_topic,
    //     page_start,
    //     page_end,
    //     weight_start,
    //     weight_end,
    //     published_start,
    //     published_end,
    //     publish_year_start,
    //     publish_year_end,
    //     created_at,
    //     created_at_start,
    //     created_at_end
    //   }
    // ) {
    //   return new Promise(function(resolve) {
    //     axios
    //       .get(
    //         env.base_url +
    //           `/search${page ? "?page=" + page : ""}
    //                     ${type ? "&type=" + type : ""}
    //                     ${q ? "&q=" + q : ""}${count ? "&count=" + count : ""}${
    //             isExist ? "&isExist=" + isExist : ""
    //           }${sort ? "&sort=" + sort : ""}${
    //             category ? "&category=" + category : ""
    //           }${brand ? "&brand=" + brand : ""}${
    //             author ? "&author=" + author : ""
    //           }${translator ? "&translator=" + translator : ""}${
    //             range_start ? "&range_start=" + range_start : ""
    //           }${range_end ? "&range_end=" + range_end : ""}${
    //             book_size ? "&book_size=" + book_size : ""
    //           }${cover_type ? "&cover_type=" + cover_type : ""}${
    //             page_start ? "&page_start=" + page_start : ""
    //           }${page_end ? "&page_end=" + page_end : ""}${
    //             weight_start ? "&weight_start=" + weight_start : ""
    //           }${weight_end ? "&weight_end=" + weight_end : ""}${
    //             published_start ? "&published_start=" + published_start : ""
    //           }${published_end ? "&published_end=" + published_end : ""}${
    //             publish_year_start
    //               ? "&publish_year_start=" + publish_year_start
    //               : ""
    //           }${
    //             publish_year_end ? "&publish_year_end=" + publish_year_end : ""
    //           }${created_at ? "&created_at=" + created_at : ""}${
    //             created_at_start ? "&created_at_start=" + created_at_start : ""
    //           }${created_at_end ? "&created_at_end=" + created_at_end : ""}${
    //             type ? "&type=" + type : ""
    //           }`
    //       )
    //       .then(result => {
    //         let url = query + "&api=true";
    //         if (result.status === 200) {
    //           resolve(result.data);
    //         }
    //       });
    //   });
    // },
    getSearchedProductsUrl(
      context,
      {
        api,
        q,
        page,
        count,
        type,
        category,
        isExist,
        sort,
        brand,
        author,
        translator,
        range_start,
        range_end,
        book_size,
        cover_type,
        // main_topic,
        page_start,
        page_end,
        weight_start,
        weight_end,
        published_start,
        published_end,
        publish_year_start,
        publish_year_end,
        created_at,
        created_at_start,
        created_at_end
      }
    ) {
      return new Promise(function(resolve) {
        let str =
          env.base_url +
          `/search${page ? "?page=" + page : ""}${api ? "&api=" + api : ""}${
            q ? "&q=" + q : ""
          }${count ? "&count=" + count : ""}${
            category ? "&category=" + category : ""
          }${isExist ? "&isExist=" + isExist : ""}${
            sort ? "&sort=" + sort : ""
          }${brand ? "&brand=" + brand : ""}${
            author ? "&author=" + author : ""
          }${translator ? "&translator=" + translator : ""}${
            range_start ? "&range_start=" + range_start : ""
          }${
            range_end && range_end < 100000000 ? "&range_end=" + range_end : ""
          }${book_size ? "&book_size=" + book_size : ""}${
            cover_type ? "&cover_type=" + cover_type : ""
          }${page_start ? "&page_start=" + page_start : ""}${
            page_end ? "&page_end=" + page_end : ""
          }${weight_start ? "&weight_start=" + weight_start : ""}${
            weight_end ? "&weight_end=" + weight_end : ""
          }${published_start ? "&published_start=" + published_start : ""}
            ${published_end ? "&published_end=" + published_end : ""}${
            publish_year_start
              ? "&publish_year_start=" + publish_year_start
              : ""
          }${publish_year_end ? "&publish_year_end=" + publish_year_end : ""}${
            created_at ? "&created_at=" + created_at : ""
          }${created_at_start ? "&created_at_start=" + created_at_start : ""}${
            created_at_end ? "&created_at_end=" + created_at_end : ""
          }${type ? "&type=" + type : ""}`;
        resolve(str);
      });
    }
  }
};
