<template>
  <div class="form col-xl-4 col-md-6">
    <h5 class="mb-3">ورود یا ثبت‌نام</h5>

    <Spinner v-if="loading"> </Spinner>

    <div v-else class="w-100">
      <!-- show error  -->
      <p v-if="$store.getters.getErrors" class="text-center" style="color: red">
        {{ $store.getters.getErrors }}
      </p>
      <!-- show error  -->

      <!-- register items  -->
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>شماره تلفن همراه:</label>
          <span
            v-if="phoneError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >شماره تلفن همراه الزامی است</span
          >
        </div>
        <input
          v-model="phone"
          ref="phone"
          type="number"
          name="phone"
          class="input mt-0"
          placeholder="شماره تلفن همراه"
        />
      </div>

      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>شماره تلفن:</label>
        </div>
        <input
          v-model="telephone"
          ref="telephone"
          type="number"
          name="telephone"
          class="input mt-0"
          placeholder="شماره تلفن"
        />
      </div>
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>نام و نام خانوادگی:</label>
          <span
            v-if="fullnameError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >نام و نام خانوادگی الزامی است</span
          >
        </div>
        <input
          v-model="fullname"
          ref="fullname"
          type="text"
          name="fullname"
          class="input mt-0"
          placeholder="نام و نام خانوادگی"
        />
      </div>
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>ایمیل:</label>
          <span
            v-if="emailError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >ایمیل الزامی است</span
          >
        </div>
        <input
          v-model="email"
          ref="email"
          class="input mt-0"
          type="email"
          name="email"
          placeholder="پست الکترونیک"
        />
      </div>
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>رمز عبور:</label>
          <span
            v-if="passwordError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >رمزعبور الزامی است</span
          >
        </div>
        <input
          v-model="password"
          ref="password"
          class="input mt-0"
          type="password"
          name="password"
          placeholder="رمز عبور"
        />
      </div>
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>تکرار رمز عبور:</label>
          <span
            v-if="passwordCError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >عدم تطابق رمز عبور و تکرار رمز عبور
          </span>
        </div>
        <input
          v-model="password_c"
          ref="password_c"
          class="input mt-0"
          type="password"
          name="password"
          placeholder="تکرار رمز عبور"
        />
      </div>
      <div class="row">
        <div class="d-flex align-items-center">
          <label>عنوان فروشگاه:</label>
          <span
            v-if="storeNameError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >نام فروشگاه الزامی است
          </span>
        </div>
        <input
          v-model="store_name"
          ref="storeName"
          type="text"
          name="storeName"
          class="input mt-0"
          placeholder="عنوان فروشگاه"
        />
      </div>
      <!-- register items -->

      <!-- buttons  -->
      <div class="d-flex">
        <input
          @click="register"
          class="submit-btn w-100 ml-3"
          type="button"
          value="ثبت‌نام"
        />
        <input
          @click="login"
          class="info-btn w-100"
          type="button"
          value="قبلا ثبت‌نام کرده‌اید"
        />
      </div>
      <!-- buttons  -->
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "register",
  components: { Spinner },

  data: function() {
    return {
      telephone: "",
      phone: "",
      fullname: "",
      email: "",
      password: "",
      password_c: "",
      store_name: "",
      loading: false
    };
  },
  computed: {
    phoneError() {
      return this.phone.length < 1 ? true : false;
    },
    fullnameError() {
      return this.fullname.length < 1 ? true : false;
    },
    emailError() {
      return this.email.length < 1 ? true : false;
    },
    passwordError() {
      return this.password.length < 1 ? true : false;
    },
    passwordCError() {
      return this.password != this.password_c ? true : false;
    },
    storeNameError() {
      return this.store_name.length < 1 ? true : false;
    }
  },
  methods: {
    register() {
      this.$store.commit("setFailedEvent", ""); //clean old error

      if (this.phoneError) {
        this.$refs.phone.focus();
        return;
      }
      // if (this.telephoneError) {
      //   this.$refs.telephone.focus();
      //   return;
      // }
      if (this.fullnameError) {
        this.$refs.fullname.focus();
        return;
      }
      if (this.emailError) {
        this.$refs.email.focus();
        return;
      }
      if (this.passwordError) {
        this.$refs.password.focus();
        return;
      }
      if (this.passwordCError) {
        this.$refs.password_c.focus();
        return;
      }
      if (this.storeNameError) {
        this.$refs.storeName.focus();
        return;
      }
      //enable loader
      this.loading = true;

      //register
      this.$store
        .dispatch("register", {
          phone: this.phone,
          telephone: this.telephone ?? "",
          fullname: this.fullname,
          email: this.email,
          password: this.password,
          store_name: this.store_name
        })
        .then(result => {
          if (!result) {
            (this.telephone = ""),
              (this.phone = ""),
              (this.fullname = ""),
              (this.email = ""),
              (this.password = ""),
              (this.password_c = ""),
              (this.store_name = "");
          } else if (result) {
            //sync basket
            this.$store.dispatch("updateBasketReq").then(res => {
              if (res) {
                this.$store.dispatch("getBasketReq");
              }
            });
            //go to welcome
            this.$router.push({ name: "ProfileInfo" });
          }
          //disable loader
          this.loading = false;
        });
    },
    login() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "LOGIN" });
    }
  }
};
</script>

<style scoped></style>
