import Cart from "../../views/pages/Cart/Shopping-Cart.vue";
import Shipping from "../../views/pages/Cart/Shipping-Info.vue";
import Checkout from "../../views/pages/Cart/Checkout.vue";
import Success from "../../views/pages/Cart/Success.vue";
import Fail from "../../views/pages/Cart/Fail.vue";
import Address from "@/views/pages/Cart/Shipping-Info";
export default [
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: { requiresAuth: true }
  },
  {
    path: "/cart/shipping",
    name: "Shopping",
    component: Shipping,
    meta: { requiresAuth: true }
  },
  {
    path: "/cart/checkout/:orderId",
    name: "Checkout",
    component: Checkout,
    meta: { requiresAuth: true }
  },
  {
    path: "/cart/checkout/:orderId/success",
    name: "Success",
    component: Success,
    meta: { requiresAuth: true }
  },
  {
    path: "/cart/checkout/:orderId/fail",
    name: "Fail",
    component: Fail,
    meta: { requiresAuth: true }
  },
  {
    path: "/cart/address",
    name: "cartAddress",
    component: Address,
    meta: { requiresAuth: true }
  }
];
