<template>
  <section class="terms">
    <breadcrumb current-page-name="قوانین و مقررات"></breadcrumb>
    <div class="wrapper">
      <div class="col-xl-8 col-md-10 col-10 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
        </h5>

        <p v-html="body"></p>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";

export default {
  name: "terms",
  components: { Breadcrumb },
  data() {
    return {
      title: "",
      body: ""
    };
  },
  created() {
    //get details from backend
    this.$store
      .dispatch("getDetails", { slug: "terms-and-condition" })
      .then(result => {
        if (result) {
          this.title = result.title;
          this.body = result.body;
        }
      });
  }
};
</script>
