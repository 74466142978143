import Vue from "vue";
import Vuex from "vuex";
import authOTP from "./modules/auth-otp.store";
import authUP from "./modules/auth-up.store";
import product from "./modules/product.store";
import basket from "./modules/shopping-card.store";
import profile from "./modules/profile.store";
import information from "./modules/information.store";
import settings from "./modules/settings.store";
import slider from "./modules/slider.store";
import category from "./modules/category.store";
import filter from "./modules/filter.store";
import search from "./modules/search.store";
import wallet from "./modules/wallet.store";
import paymentways from "./modules/paymentways.store";
import sliders from "./modules/sliders.store";
import blog from "./modules/blog.store";
import productsList from "./modules/productsList.store";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authOTP,
    authUP,
    product,
    basket,
    profile,
    information,
    settings,
    slider,
    category,
    filter,
    search,
    wallet,
    paymentways,
    sliders,
    blog,
    productsList
  }
});
