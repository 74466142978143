<template>
  <div class="form col-12">
    <h5 class="mb-3">تایید حساب</h5>
    <Spinner v-if="loading"> </Spinner>

    <div v-else>
      <!-- show error  -->
      <p v-if="$store.getters.getErrors" class="text-center" style="color: red">
        {{ $store.getters.getErrors }}
      </p>
      <!-- show error  -->

      <p class="mt-3 text-center">کد ارسال شده را وارد نمایید</p>
      <CodeInput
        class="ltr m-auto"
        :loading="false"
        v-on:complete="onComplete"
        :fields="4"
        type="number"
        v-model="code"
        :fieldWidth="75"
        :fieldHeight="40"
      />
      <div class="">
        <p class="text-center">
          <b v-if="again == false"
            >ارسال مجدد کد تا <span>{{ timer }}</span> ثانیه دیگر</b
          >
          <a
            v-if="again == true"
            style="cursor: pointer"
            class="mt-3"
            @click="resend()"
          >
            ارسال مجدد
            <i class="fa fa-refresh"></i>
          </a>
        </p>
      </div>

      <div class="group-btns d-flex">
        <input
          @click="checkVerifyCode"
          class="submit-btn w-100 ml-3 mr-3 mb-3"
          type="button"
          value="تایید و ادامه"
        />
        <input
          @click="login"
          class="info-btn w-100 ml-3 mr-3 mb-3"
          type="button"
          value="ورود"
        />
        <input
          @click="register"
          class="info-btn w-100 mb-3"
          type="button"
          value="حساب کاربری ندارم"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CodeInput from "vue-verification-code-input";
import Spinner from "@/components/Spinner.component";

export default {
  name: "verify",
  components: {
    CodeInput,
    Spinner
  },
  data: function() {
    return {
      loading: false,
      timer: "00:00",
      again: false,
      code: ""
    };
  },

  created() {
    let minute = 1;
    let second = 59;

    setInterval(() => {
      this.timer = `${minute}:${(second -= 1)}`;
      if (minute > 0 && second == 0) {
        minute -= 1;
        second = 59;
      }
      if ((minute == 0) & (second == 0)) {
        this.again = true;
        minute = 1;
        second = 59;
      }
    }, 1000);
  },
  methods: {
    onComplete(v) {
      this.code = v;
    },
    checkVerifyCode() {
      //enable loader
      this.loading = true;

      //checkVerifyCode
      this.$store
        .dispatch("checkVerifyCode", {
          email: this.$store.getters.getEmail,
          code: this.code
        })
        .then(result => {
          if (result) {
            //sync basket
            this.$store.dispatch("updateBasketReq").then(res => {
              if (res) {
                this.$store.dispatch("getBasketReq");
              }
            });

            //go to change pass
            this.$router.push({ name: "ChangePassword" });

            //disable loader
            this.loading = false;
          } else {
            this.code = "";
            //disable loader
            this.loading = false;
          }
        });
    },
    resend() {
      this.$store.commit("setFailedEvent", ""); //clean old error

      if (
        !this.$store.getters.getEmail ||
        this.$store.getters.getEmail === ""
      ) {
        this.$store.commit("setUPAuthRoutineLevel", { status: "FORGOT" });
        return;
      }

      //enable loader
      this.loading = true;

      //send verify code
      this.$store
        .dispatch("forgot", {
          email: this.$store.getters.getEmail
        })
        .then(() => {
          //disable loader
          this.loading = false;
          this.again = false;
        });
    },
    login() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "LOGIN" });
    },
    register() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "REGISTER" });
    }
  }
};
</script>

<style scoped></style>
