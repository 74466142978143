<template>
  <header id="section-header">
    <div
      v-if="$store.getters.showBasketStatus === true"
      class="shoping-cart"
    ></div>
    <SideBasket></SideBasket>

    <div class="custom-navbar col-lg-10 m-auto pt-3 pb-3">
      <!-- mobile header -->
      <div class="d-lg-none d-flex w-100 justify-content-around mb-3">
        <div class="d-flex">
          <i class="fa fa-bars" @click="openSidebar"></i>
          <div title="سبد خرید" style="height: 35px; margin: 0 1rem">
            <a @click="basketChangeStatus()" href="#">
              <img src="/template/images/Bag.svg" width="25px" alt="shoping" />
            </a>
            <div class="circle">
              <p class="text-center">{{ productCounter }}</p>
            </div>
          </div>
        </div>
        <div class="logo m-auto">
          <router-link to="/">
            <img src="../../../public/img/logo.png" alt="logo" width="70px" />
          </router-link>
        </div>
        <div class="icons d-flex">
          <ul class="">
            <li>
              <!-- profile -->
              <div title="حساب کاربری" class="profile" style="height: 50px">
                <a @click="showQuickAccessProfile()" href="#" class="w-max">
                  <img src="/template/images/User.svg" alt="profile" />
                  <span data-v-16df2a11="" class="header-span"
                    >حساب کاربری </span
                  ><i data-v-16df2a11="" class="fa fa-caret-down"></i>
                </a>

                <div class="sign-in">
                  <Walletinfo
                    v-if="$store.getters.getToken !== null"
                  ></Walletinfo>

                  <input
                    v-if="$store.getters.getToken !== null"
                    class="submit-btn"
                    @click="
                      $router.push('/profile');
                      showProfileQuickAccess = false;
                    "
                    type="button"
                    value="مشاهده‌ی حساب کاربری"
                  />
                  <input
                    v-if="$store.getters.getToken !== null"
                    @click="logoutProfile()"
                    type="button"
                    class="info-btn mt-3"
                    value="خروج از حساب کاربری"
                  />
                  <input
                    v-else
                    @click="
                      $router.push({ path: '/auth' }).catch(() => {});
                      showProfileQuickAccess = false;
                    "
                    type="button"
                    class="submit-btn"
                    value="ورود یا ثبت‌نام"
                  />
                </div>
              </div>
              <!-- profile -->
            </li>
            <li>
              <div title="علاقه‌مندی‌ها" class="">
                <router-link tag="a" to="/profile/favorite" class="w-max">
                  <img
                    src="/template/images/Heart.svg"
                    alt="favorites"
                    width="25px"
                  />
                  <span class="header-span"> علاقه‌مندی‌ها</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- mobile header -->

      <!-- screen header -->
      <div class="d-none d-lg-flex">
        <div class="logo">
          <router-link to="/">
            <img src="../../../public/img/logo.png" alt="logo" width="70px" />
          </router-link>
        </div>
        <div title="سبد خرید" style="height: 35px; margin: 0 1rem">
          <a @click="basketChangeStatus()" href="#">
            <img src="/template/images/Bag.svg" width="25px" alt="shoping" />
          </a>
          <div class="circle">
            <p class="text-center">{{ productCounter }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex w-100">
        <search
          placeholder="جست‌وجو "
          :more-res="true"
          style="width: 100%"
        ></search>
      </div>
      <div class="icons d-none d-lg-flex">
        <ul class="">
          <li>
            <!-- profile -->
            <div title="حساب کاربری" class="profile" style="height: 50px">
              <a @click="showQuickAccessProfile()" href="#" class="w-max">
                <img src="/template/images/User.svg" alt="profile" />
                <span data-v-16df2a11="" class="header-span">حساب کاربری </span
                ><i data-v-16df2a11="" class="fa fa-caret-down"></i>
              </a>

              <div class="sign-in">
                <Walletinfo
                  v-if="$store.getters.getToken !== null"
                ></Walletinfo>

                <input
                  v-if="$store.getters.getToken !== null"
                  class="submit-btn"
                  @click="
                    $router.push('/profile');
                    showProfileQuickAccess = false;
                  "
                  type="button"
                  value="مشاهده‌ی حساب کاربری"
                />
                <input
                  v-if="$store.getters.getToken !== null"
                  @click="logoutProfile()"
                  type="button"
                  class="info-btn mt-3"
                  value="خروج از حساب کاربری"
                />
                <input
                  v-else
                  @click="
                    $router.push({ path: '/auth' }).catch(() => {});
                    showProfileQuickAccess = false;
                  "
                  type="button"
                  class="submit-btn"
                  value="ورود یا ثبت‌نام"
                />
              </div>
            </div>
            <!-- profile -->
          </li>
          <li>
            <div title="علاقه‌مندی‌ها" class="">
              <router-link tag="a" to="/profile/favorite" class="w-max">
                <img
                  src="/template/images/heart.svg"
                  alt="favorites"
                  width="25px"
                />
                <span class="header-span"> علاقه‌مندی‌ها</span>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- screen header -->
    </div>
    <MegaMenu :items="menuItems"></MegaMenu>

    <Sidebar ref="sidebar" :items="menuItems"></Sidebar>
  </header>
</template>

<script>
import SideBasket from "@/components/SideBasket.component.vue";
import Sidebar from "@/components/Sidebar.component.vue";
import Search from "@/components/Search.component.vue";
import MegaMenu from "@/components/MegaMenu.component.vue";
import Walletinfo from "@/components/walletInfo.component";

export default {
  name: "Header",
  data: function() {
    return {
      menuItems: [],
      showProfileQuickAccess: false
    };
  },
  components: {
    SideBasket,
    Sidebar,
    Search,
    MegaMenu,
    Walletinfo
  },
  computed: {
    productCounter() {
      return this.$store.getters.getBasket.length;
    }
  },
  created() {
    this.$store.dispatch("getMenu").then(result => {
      this.menuItems = result;
    });
  },
  methods: {
    basketChangeStatus: function() {
      this.$store.dispatch("setBasketStatus", {
        status: !this.$store.getters.showBasketStatus
      });
    },
    showQuickAccessProfile: function() {
      this.showProfileQuickAccess = !this.showProfileQuickAccess;
    },
    logoutProfile() {
      this.$swal
        .fire({
          position: "center",
          icon: "warning",
          title: "آیا از خروج خود اطمینان دارید؟",
          showCancelButton: true,
          confirmButtonText: `تایید`,
          cancelButtonText: `انصراف`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch("logout").then(() => {
              window.location.href = "/auth";
            });
          }
        });
    },
    openSidebar() {
      this.$refs.sidebar.open();
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped>
.custom-navbar {
  padding: 0 3rem;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

.custom-navbar .menu-item {
  padding-top: 20px;
}

.custom-navbar,
.custom-navbar-menu {
  display: flex;
}

@media screen and (max-width: 500px) {
  .custom-navbar {
    flex-flow: column-reverse;
    margin-bottom: 1rem;
  }
  .header-navbar {
    justify-content: center;
    padding: 0 !important;
  }
}

.custom-navbar a {
  /* float: left; */
  font-size: 14px;
  color: #000;
  text-align: center;
  /* padding: 10px; */
  text-decoration: none;
  display: list-item;
  list-style: none;
}

.menu-border {
  -webkit-box-shadow: -3px 0px 9px -6px #c7c7c7;
  box-shadow: -3px 0px 9px -6px #c7c7c7;
}

@media (max-width: 1030px) {
  .custom-navbar a {
    font-size: 12px;
  }
}

.custom-navbar a:hover {
  text-decoration: none;
  color: #ff4476;
}

.custom-navbar .fa-bars {
  cursor: pointer;
  font-size: 20px;
  margin: auto 1rem;
}

@media screen and (max-width: 900px) {
  .custom-navbar {
    display: flex;
    flex-flow: column;
  }
  .custom-navbar .search {
    margin: 0 !important;
  }
  .sign-in {
    left: 3rem;
  }
}
@media screen and (max-width: 450px) {
  .custom-navbar {
    padding: 0 10px;
  }
  .custom-navbar .fa-bars {
    cursor: pointer;
    font-size: 20px;
    margin: auto 5px;
  }
}
.icons ul {
  list-style: none;
  display: flex;
  margin: 0;
}
.icons ul li {
  margin: 0.25rem;
  height: 30px;
}
.icons ul li:last-child {
  border-right: 1px solid #000;
  padding-right: 10px;
}
.custom-navbar .search {
  margin: 0 2rem;
}
</style>
