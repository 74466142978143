<template>
  <Spinner
    v-if="loading && products && products.length !== 0"
    :circle="true"
  ></Spinner>

  <div
    v-else-if="products && products.length !== 0"
    class="amazing row mt-5 mb-5"
    :style="`background-color:${color}`"
  >
    <div
      class="
        col-lg-2
        mb-3
        mt-3
        title
        d-flex
        flex-column
        justify-content-around
        align-items-center
      "
    >
      <h4 class="text-right">قفسه‌ی شگفت‌انگیز</h4>
      <img
        v-if="logo"
        :src="logo"
        class="img-responsive"
        style="width: 100%; max-width: 100%; height: auto; position: relative"
      />
      <router-link
        class="info-btn"
        style="color: white; border-color: white; background-color: transparent"
        tag="b"
        :to="link"
      >
        نمایش بیشتر
        <i class="fa fa-chevron-left mr-2"></i>
      </router-link>
    </div>
    <div class="col-lg-10 m-auto" align="center">
      <VueSlickCarousel v-bind="settings">
        <OffersProductItem
          v-for="(product, index) in products"
          :key="product[index]"
          :product="product"
          class="ml-2 mr-2"
        ></OffersProductItem>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import OffersProductItem from "@/components/OffersProductItem.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "amazing",
  components: {
    VueSlickCarousel,
    OffersProductItem,
    Spinner
  },
  data: function() {
    return {
      loading: true,
      firstItem: [],
      products: [],
      link: "/product-selective/amazing",
      logo: "",
      color: "",
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 1
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {
    //get products per category
    this.$store.dispatch("getOfferProducts").then(result => {
      this.products = result.items;
      this.logo = result.logo;
      this.color = result.color ? result.color : "#ef394e";
      if (result.link_show_all) {
        this.link = result.link_show_all;
      }
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.amazing h4 {
  color: #fff;
}

.amazing {
  padding: 3.5rem 0.5rem;
  margin: 1rem;
  border-radius: 10px;
  /* background-color: #ef394e; */
}

.amazing .main-container {
  background-color: #fff !important;
}

@media only screen and (max-width: 1000px) {
  .amazing .info-btn {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 400px) {
  .amazing .title {
    font-size: 11px !important;
  }

  .amazing {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
