<template>
  <div class="newsletter d-flex flex-column mt-5">
    <h5>عضویت در خبرنامه</h5>
    <p>با اشتراک در خبرنامه از آخرین اخبار و تخفیف‌های ما با خبر شوید.</p>
    <div class="form-group d-flex justify-content-center">
      <input
        class="input"
        type="email"
        placeholder="ایمیل خود را وارد کنید"
        name="user-email"
        v-model="email"
      />

      <button v-if="Loading" class="submit-btn">
        <div class="spinner-border text-white spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>

      <button v-else class="submit-btn" @click="subscribe()">ثبت</button>
    </div>

    <label :style="messageColor" v-if="newsletterMessage != ''">
      {{ newsletterMessage }}
    </label>
  </div>
</template>

<style scoped></style>
<script>
export default {
  data() {
    return {
      Loading: false,
      email: "",
      newsletterMessage: "",
      messageColor: "color:red"
    };
  },
  methods: {
    subscribe() {
      if (this.email) {
        if (this.validateEmail(this.email)) {
          this.Loading = true;
          this.$store
            .dispatch("subscribe_newsletter", { email: this.email })
            .then(result => {
              if (result.success) {
                console.log(result);
                this.newsletterMessage = "با موفقیت ثبت گردید !";
                this.messageColor = "color:green";
                setTimeout(() => {
                  this.newsletterMessage = "";
                }, 3000);
              } else {
                this.newsletterMessage = "شما قبلا ثبت‌نام کرده‌اید !";
                this.messageColor = "color:red";
                setTimeout(() => {
                  this.newsletterMessage = "";
                }, 3000);
              }

              this.Loading = false;
            });
        } else {
          this.newsletterMessage = "ایمیل نامعتبر است.";
          this.messageColor = "color:red";
          setTimeout(() => {
            this.newsletterMessage = "";
          }, 3000);
        }
      } else {
        this.newsletterMessage = "ایمیل نمی تواند خالی باشد !";
        setTimeout(() => {
          this.newsletterMessage = "";
        }, 3000);
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  }
};
</script>
