import axios from "axios";
import { env } from "../../../enums";
export default {
  state: {
    wallet: 0
  },
  getters: {
    getWallet(state) {
      return state.wallet;
    }
  },
  mutations: {
    setWallet(state, data) {
      state.wallet = data;
    }
  },
  actions: {
    getWalletBalance(context) {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/wallet/balance", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            context.commit("setWallet", result.data);
            resolve(result.data);
          });
      });
    },
    getWalletHistory() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/wallet/history", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    Newdeposit(state, amount) {
      return new Promise(resolve => {
        axios
          .post(
            env.base_url + "/wallet/deposit",
            {
              amount
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    verifyPayment(state, response) {
      return new Promise(resolve => {
        let Authority = response.Authority;
        let Status = response.Status;
        axios
          .post(
            env.base_url + "/wallet/verify",
            {
              Authority,
              Status
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    giftCharge(state, code) {
      return new Promise(resolve => {
        axios
          .post(
            env.base_url + "/wallet/gift-charge",
            {
              code
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(() => {
            return resolve({status: "ok"});
          }).catch(() => {
            return resolve({status: "fail"});
          });
      });
    },
  }
};
