<template>
  <section>
    <Breadcrumb current-page-name="داشبورد کاربر"></Breadcrumb>
    <div class="custom-container">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <div class="col-lg-8">
          <div class="row">
            <!-- personal info -->
            <div class="col-lg-5 customer-panel-box">
              <div class="h">اطلاعات شخصی</div>

              <Spinner v-if="infoLoading"></Spinner>

              <div v-else>
                <div class="input-group">
                  <label>نام و نام خانوادگی:</label>
                  <span>
                    {{ this.$store.getters.getProfileInfo.fullname }}
                  </span>
                </div>

                <div class="input-group">
                  <label> شماره تلفن همراه:</label>
                  <span>
                    {{ this.$store.getters.getProfileInfo.phone }}
                  </span>
                </div>

                <div class="input-group">
                  <label> پست الکترونیک:</label>
                  <span>
                    {{ this.$store.getters.getProfileInfo.email }}
                  </span>
                </div>

                <div class="input-group">
                  <label>کد پرسنلی :</label>
                  <span>
                    {{ this.$store.getters.getProfileInfo.personnel_code }}
                  </span>
                </div>


                <router-link
                  tag="button"
                  class="info-btn"
                  style="font-size: 14px"
                  to="/profile/edit"
                >
                  <img src="/template/images/edit-info.svg" alt="" />
                  ویرایش اطلاعات
                </router-link>
              </div>
            </div>
            <!-- personal info -->

            <!-- favorites -->
            <div class="col-lg-6 customer-panel-box">
              <div class="h">لیست آخرین علاقه‌مندی‌ها</div>
              <Spinner v-if="favoritesLoading"></Spinner>
              <div v-else class="custom-table-container mt-3">
                <table class="custom-table">
                  <thead>
                    <tr class="h">
                      <th>تصویر</th>
                      <th>عنوان</th>
                      <th>نویسنده</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in favorites" :key="index">
                      <td>
                        <img
                          width="50px"
                          class="product-img"
                          style="cursor: pointer"
                          @click="$router.push('/product/' + product.slug)"
                          :src="product.image"
                          alt="img-product"
                        />
                      </td>
                      <td
                        style="cursor: pointer"
                        @click="$router.push('/product/' + product.slug)"
                      >
                        {{ product.name }}
                      </td>
                      <td>{{ product.author }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- favorites -->
          </div>

          <!-- orders -->
          <div class="customer-panel-box">
            <div class="h">آخرین سفارش‌ها</div>
            <Spinner v-if="ordersLoading"></Spinner>
            <div v-else class="custom-table-container mt-3">
              <table v-if="currentOrders.length != 0" class="custom-table">
                <tbody>
                  <tr
                    v-for="(order, index) in currentOrders"
                    :key="order[index]"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ "PAKAT_" + order.id }}
                    </td>
                    <td>
                      {{ order.created_at | moment("jYY-jMM-jDD HH:mm") }}
                    </td>
                    <td>
                      {{ new Intl.NumberFormat().format(order.total_price) }}
                      ریال
                    </td>
                    <td>در حال پردازش و آماده‌سازی</td>
                    <td>
                      <router-link
                        tag="input"
                        class="info-btn"
                        type="button"
                        value="جزئیات سفارش"
                        :to="'/profile/orders/order/' + order.id"
                      ></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- orders -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";
export default {
  name: "Profile",
  data() {
    return {
      currentOrders: [],
      favorites: [],
      infoLoading: true,
      ordersLoading: true,
      favoritesLoading: true
    };
  },
  components: { Breadcrumb, SideMenu, Spinner },
  beforeCreate() {
    this.$store.dispatch("getProfileX").then(() => {
      this.infoLoading = false;
    });
  },
  mounted() {},
  created() {
    this.$store
      .dispatch("getOrders", {
        orderType: "current",
        page: 1
      })
      .then(result => {
        this.currentOrders = result.data;
        this.ordersLoading = false;
      });
    this.$store.dispatch("getFavorite").then(result => {
      this.favorites =
        result.item.length > 2
          ? [result.item[0], result.item[1], result.item[2]]
          : result.item;
      this.favoritesLoading = false;
    });
  }
};
</script>

<style scoped>
.input-group {
  margin-top: 1rem;
}
.input-group label {
  margin-left: 1rem;
}
</style>
