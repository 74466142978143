<template>
  <div class="text-center comments">
    <br />

    <button @click="addComment" class="submit-btn m-2">
      <i class="fa fa-plus"></i>
      افزودن دیدگاه جدید
    </button>
    <Spinner v-if="loading"> </Spinner>

    <div v-else-if="showInsertComment" class="text-right comment-box mt-3">
      <div class="comment-title">
        عنوان دیدگاه
        <input class="input" type="text" v-model="title" />
      </div>
      <div class="comment-text">
        متن دیدگاه
        <textarea v-model="description" class="input"></textarea>
      </div>
      <div class="text-left">
        <input
          class="submit-btn"
          @click="submitComment()"
          type="button"
          value="ثبت دیدگاه"
        />
      </div>
    </div>
    <div>
      <div
        v-for="(comment, index) in comments"
        :key="comment[index]"
        class="comment"
      >
        <div class="comment-header">
          <img
            class="p-img"
            src="/template/images/profile.svg"
            alt=""
            width="80px"
          />
          <p>
            {{ comment.get_customer.fullname }}
          </p>
          <span>{{ comment.created_at | moment("jYY-jMM-jDD") }}</span>
        </div>
        <div class="comment-body">
          <h6 class="mb-3">{{ comment.title }}</h6>
          <p>
            {{ comment.description }}
          </p>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "comment.component",
  components: { Spinner },
  data() {
    return {
      showInsertComment: false,
      title: "",
      description: "",
      loading: false
    };
  },
  methods: {
    submitComment: function() {
      if (
        this.title &&
        this.title.length < 200 &&
        this.description &&
        this.description.length < 500 &&
        localStorage.getItem("token") != null
      ) {
        this.loading = true;
        this.$store
          .dispatch("saveComment", {
            title: this.title,
            description: this.description,
            productId: this.$props["productId"]
          })
          .then(result => {
            if (result) {
              this.title = "";
              this.description = "";
              this.$swal.fire({
                position: "center",
                icon: "success",
                title:
                  "دیدگاه شما ثبت گردید پس از تایید مدیریت نمایش داده خواهد شد !",
                showConfirmButton: false,
                timer: 2000
              });
            }
            this.loading = false;
          });
      } else {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "لطفا اطلاعات را به درستی و کوتاه پر کنید !",
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    addComment() {
      if (this.showInsertComment) {
        this.showInsertComment = false;
      } else if (localStorage.getItem("token") != null) {
        this.showInsertComment = true;
      } else {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "لطفا ابتدا وارد شوید !",
          showConfirmButton: false,
          timer: 2000
        });
      }
    }
  },
  props: ["productId", "comments"]
};
</script>

<style scoped>
.comments {
}
.comment {
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
  border-top: 1px solid #e7e7e7;
  padding-top: 0.5rem;
}
.comment-header {
  display: flex;
  align-items: center;
}
.comment-header p {
  margin: 0 1rem;
}
.comment-body {
  padding: 1rem;
  margin-right: 2.5rem;
  margin-top: 1rem;
  border-radius: 20px;
  border: 1px solid gray;
  border-top-right-radius: 0px;
}
</style>
