<template>
  <section class="slider-section">
    <carousel :per-page="1" :autoplay="true" :loop="true">
      <slide
        data-index="0"
        data-name="slider"
        v-for="(item, i) in items"
        :key="i"
      >
        <a :href="item.url">
          <img :src="item.image" :title="item.name" :alt="item.name" />
        </a>
      </slide>
    </carousel>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: ["items"],
  components: {
    Carousel,
    Slide
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped>
.slider-section .VueCarousel {
  direction: ltr;
  max-height: 600px;
}

.slider-section .VueCarousel-slide {
  display: flex;
}

.slider-section .VueCarousel-slide a {
  width: 100%;
}

.slider-section .VueCarousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
