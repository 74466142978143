import Vue from "vue";
import VueRouter from "vue-router";
import AuthRouter from "./routes/auth.route";
import Home from "../views/pages/Home.vue";
import About from "../views/pages/About.vue";
import ProductRouter from "./routes/product.route";
import ProfileRouter from "./routes/profile.route";
import CartRouter from "./routes/cart.route";
import BlogRouter from "./routes/blog.router";
import Contact from "../views/pages/Contact.vue";
import Terms from "../views/pages/Terms.vue";
import FAQ from "../views/pages/FAQ.vue";
//
import ProductsList from "../views/pages/ProductsList";
import Category from "../views/pages/Category";
import Search from "../views/pages/Search";
Vue.use(VueRouter);
const routes = [
  ...AuthRouter,
  {
    path: "/",
    name: "Home",
    component: Home
  },
  ...ProductRouter,
  ...ProfileRouter,
  ...CartRouter,
  ...BlogRouter,
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/About.vue")
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: Contact
  },
  {
    path: "/about/:slug",
    name: "about",
    component: About
  },
  {
    path: "/category/:slug",
    name: "category",
    component: Category
  },
  {
    path: "/terms-and-condition",
    name: "terms and condition",
    component: Terms
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ
  },
  {
    path: "/product/:slug/list",
    name: "productsList",
    component: ProductsList
  },
  {
    path: "/search",
    name: "search",
    component: Search
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.requiresAuth && !localStorage.getItem("token")) {
    next({
      name: "auth"
    });
  } else if (localStorage.getItem("token") && to.meta.isAuthRoute) {
    next({
      name: "home"
    });
  } else {
    next();
  }
});
export default router;
