<template>
  <div class="form col-12">
    <h5 class="mb-3">ورود یا ثبت‌نام</h5>

    <!-- show error  -->
    <p
      v-if="$store.getters.getAuthErrors"
      class="text-center"
      style="color: red"
    >
      {{ $store.getters.getAuthErrors }}
    </p>
    <!-- show error  -->

    <Spinner v-if="loading"></Spinner>

    <!-- login items  -->
    <div v-if="!loading" class="w-100">
      <div class="row mb-3">
        <div class="d-flex align-items-center">
          <label>شماره تلفن:</label>
          <span
            v-if="phoneError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >شماره موبایل الزامی است</span
          >
          <span
            v-if="phoneInvalid"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >شماره موبایل نامعتبر است</span
          >
        </div>
        <input
          class="input"
          type="number"
          name=""
          pattern="\d*"
          maxlength="9"
          size="5"
          placeholder="شماره موبایل"
          v-model="phone"
          ref="phone"
          @keyup="phoneNumber()"
          @change="phoneNumber()"
        />
      </div>
    </div>
    <!-- login items -->

    <!-- buttons  -->
    <div v-if="!loading" class="d-flex">
      <input
        @click="sendVerifyCode"
        class="submit-btn w-100 ml-3"
        type="button"
        value="ورود"
      />
      <!-- <input
        @click="register"
        class="info-btn w-100"
        type="button"
        value="حساب کاربری ندارم"
      /> -->
    </div>
    <!-- buttons  -->
  </div>
</template>
<script>
import Spinner from "../../components/Spinner.component";

export default {
  data: function() {
    return {
      phone: "",
      phoneInvalid: false,
      loading: false
    };
  },
  components: { Spinner },
  computed: {
    phoneError() {
      return this.phone.length < 1 ? true : false;
    }
  },
  methods: {
    phoneNumber() {
      //var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (this.phone.length == 11) {
        let phoneno = /(09)[0-9]{9}/;
        if (this.phone.match(phoneno)) {
          this.phoneInvalid = false;
        } else {
          this.phoneInvalid = true;
        }
      }
    },
    sendVerifyCode() {
      this.$store.commit("setAuthError", ""); //clean old error

      if (this.phoneError || this.phoneInvalid) {
        this.$refs.phone.focus();
        return;
      }
      //enable loader
      this.loading = true;

      //send verify code
      this.$store
        .dispatch("sendVerifyCode", {
          phone: this.phone
        })
        .then(() => {
          if (this.$store.getters.getAuthError) {
            this.phone = "";
          }
          //disable loader
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped></style>
