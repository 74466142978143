<template>
  <section>
    <Breadcrumb
      current-page-name="ویرایش حساب کاربری"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <Spinner v-if="loading"> </Spinner>
        <div v-else class="col-lg-8 customer-panel-box">
          <div class="row h">اطلاعات کاربری</div>
          <div class="row" style="margin-top: 1rem">
            <div class="input-group col-md-6">
              نام و نام خانوادگی:
              <input
                class="input"
                v-model="fullname"
                ref="fullname"
                type="text"
                name=""
                placeholder="نام و نام خانوادگی"
              />
            </div>
            <div class="input-group col-md-6">
              شماره تلفن همراه:
              <input
                class="input"
                disabled
                v-model="phone"
                ref="phone"
                type="text"
                placeholder=""
              />
            </div>
            <div class="input-group col-md-6">
              ایمیل:
              <input
                class="input"
                v-model="email"
                ref="email"
                type="email"
                name=""
                placeholder="پست الکترونیک"
              />
            </div>
          </div>
          <input
            @click="saveData()"
            type="button"
            class="submit-btn"
            value="ثبت اطلاعات و بازگشت"
            style="margin-left: 1rem; margin-right: auto"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Breadcrumb from "@/components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-edit",
  components: { Breadcrumb, SideMenu, Spinner },
  data: function() {
    return {
      fullname: "",
      phone: "",
      email: "",
      loading: false
    };
  },
  computed: {},
  methods: {
    saveData() {
      // TODO Validation
      if (this.fullname.length === 0) {
        this.$refs.fullname.focus();
        return;
      }
      if (this.phone.length === 0) {
        this.$refs.phone.focus();
        return;
      }
      if (this.email.length === 0) {
        this.$refs.email.focus();
        return;
      }
      this.loading = true;

      this.$store
        .dispatch("saveProfileInfo", {
          fullname: this.fullname,
          phone: this.phone,
          email: this.email
        })
        .then(res => {
          if (res) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "تغییرات شما با موفقیت لحاظ گردید.",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "خطا در ویرایش اطلاعات!",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("getProfile").then(data => {
      this.fullname = data.fullname;
      this.phone = data.phone;
      this.email = data.email;
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
