import Auth from "../../views/pages/Auth-OTP/Auth.vue";
// import Auth from "../../views/pages/Auth-UP/Auth.vue"; // user password

export default [
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    meta: { isAuthRoute: true }
  }
];
